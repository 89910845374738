// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Warning_warningsection__PoVKb {\n    height: 100vh;\n    justify-content: center;\n    align-items: center;\n    display: flex;\n  }\n  \n  .Warning_nowindowstickerwarning__JS\\+HS {\n    max-width: 320px;\n    text-align: center;\n    border: 1px solid #d4d4d4;\n    border-radius: 5px;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding: 30px 30px 0;\n    display: flex;\n  }\n  \n  .Warning_ymmt__iuOQ0 {\n    border-radius: 3px;\n    margin-bottom: 15px;\n    font-weight: 600;\n  }\n  \n  .Warning_phonelink__FQKAa {\n    color: #0173de;\n    padding-top: 12px;\n    font-weight: 600;\n    text-decoration: none;\n    display: block;\n  }", "",{"version":3,"sources":["webpack://./src/features/warning/Warning.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,oBAAoB;IACpB,aAAa;EACf;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,cAAc;IACd,iBAAiB;IACjB,gBAAgB;IAChB,qBAAqB;IACrB,cAAc;EAChB","sourcesContent":[".warningsection {\n    height: 100vh;\n    justify-content: center;\n    align-items: center;\n    display: flex;\n  }\n  \n  .nowindowstickerwarning {\n    max-width: 320px;\n    text-align: center;\n    border: 1px solid #d4d4d4;\n    border-radius: 5px;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding: 30px 30px 0;\n    display: flex;\n  }\n  \n  .ymmt {\n    border-radius: 3px;\n    margin-bottom: 15px;\n    font-weight: 600;\n  }\n  \n  .phonelink {\n    color: #0173de;\n    padding-top: 12px;\n    font-weight: 600;\n    text-decoration: none;\n    display: block;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warningsection": "Warning_warningsection__PoVKb",
	"nowindowstickerwarning": "Warning_nowindowstickerwarning__JS+HS",
	"ymmt": "Warning_ymmt__iuOQ0",
	"phonelink": "Warning_phonelink__FQKAa"
};
export default ___CSS_LOADER_EXPORT___;
