import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { sendLeadFormData } from './leadFormAPI';

const initialState = {
  data: {}
};


// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchJsonData(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const sendLeadForm = createAsyncThunk(
  'leadForm/sendData',
  async (payload) => {
    const response = await sendLeadFormData(payload);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const leadFormSlice = createSlice({
  name: "leadForm",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  
  reducers: {},

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(sendLeadForm.pending, (state) => {
        //state.status = 'loading';
      })
      .addCase(sendLeadForm.fulfilled, (state, action) => {
        // state.status = 'idle';
        // state.data = action.payload;
      });
  },
});

// export const vin = (state) => state.home.vin;
// export const subdomain = (state) => state.home.subdomain;
// export const data = (state) => state.scheduleForm.data;
// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default leadFormSlice.reducer;


