import styles from "./Dealerinfomodal.module.css";
import parentStyles from "../home/Home.module.css";
import { useEffect, useState } from "react";

import icon_phone_dark from "./../../images/icon_phone_dark.svg";
import icon_directions from "./../../images/icon-directions.svg";
import { ReactComponent as Plus } from "./../../images/plus.svg";
import { ReactComponent as Minus } from "./../../images/minus-solid.svg";
import { PatternFormat } from "react-number-format";
import { motion, useAnimation } from "framer-motion";
import { listAnimation } from "./../../animation";

export function Dealerinfomodal(props) {
  const [listIcon, setListIcon] = useState([]);
  const [listDataShow, setListDataShow] = useState([]);
  const [openListIndex, setOpenListIndex] = useState(-1);
  const controls = [
    useAnimation(),
    useAnimation(),
    useAnimation(),
    useAnimation(),
  ];
  // console.log(props)
  useEffect(() => {
    const listIconDefault = [
      {
        socialMediaIcon: "./../../images/facebook.svg",
        linkSocialMedia: "facebook",
      },
      {
        socialMediaIcon: "./../../images/icon-dealer-rater.svg",
        linkSocialMedia: "dealerRater",
      },
      {
        socialMediaIcon: "./../../images/instagram.svg",
        linkSocialMedia: "instagram",
      },
      {
        socialMediaIcon: "./../../images/twitter.svg",
        linkSocialMedia: "twitter",
      },
    ];
    // this if added 06/11 - L
    if(props.socialMedia) {
      const listIconModified = listIconDefault.map((item) => {
        item.linkSocialMedia = props.socialMedia[item.linkSocialMedia];
        return item;
      });
      setListIcon(listIconModified);
    } else {
      return;
    }
  }, [props.socialMedia]);
  
  useEffect(() => {
    let listHoursData = [];
    // this if added 06/11 - L
    if (props.shopData) {
      props.shopData.forEach((item) => {
        listHoursData.push({
          hours: item.hours,
          showList: item.showList,
          name: item.name,
        });
      });
      setListDataShow(listHoursData);
    }
  }, [props.shopData]);

  const CategoryHours = (props) => {
    const listMarkup = props.hours.map((item, index) => {
      if (item.start === 'closed' || item.stop === 'closed' || item.start === '' || item.stop === '') {
        return null;
      }
      return (
        <div key={index} className={styles.hoursitem}>
          <div>{item.day}</div>
          <div className="line-item-value">
            {item.start} - {item.stop}
          </div>
        </div>
      );
    });
    return listMarkup;
  };

  const displayList = async (index) => {
    const isListClosed = openListIndex !== index;
    if (isListClosed) {
      setOpenListIndex(index);
    }
    await listAnimation(isListClosed, controls[index]);
    if (!isListClosed) {
      setOpenListIndex(-1);
    }
  };

  const List = (props) => {
    if (listDataShow.length === 0) {
      return null;
    }
    const listMarkup = listDataShow.map((item, index) => {
      if (!item.hours || !item.hours.length) {
        return null;
      }
      return (
        <motion.div
          key={index}
          onClick={() => displayList(index)}
          className={styles.listClosed}
          animate={controls[index]}
        >
          <div
            className={
              parentStyles.accTitle +
              " " +
              parentStyles.accTitleMb0 +
              " " +
              parentStyles.accTitleWopen +
              " mb0 w-dropdown-toggle"
            }
          >
            <div>{item.name}</div>
            <div className={parentStyles.faqIcon}>
              {openListIndex === index ? <Minus /> : <Plus />}
            </div>
          </div>
          <nav
            className={
              parentStyles.accBody +
              " " +
              (openListIndex !== index ? styles.navClosed : styles.navOpened)
            }
          >
            <div className={parentStyles.accBodyContent}>
              <CategoryHours hours={item.hours} />
            </div>
          </nav>
        </motion.div>
      );
    });
    return listMarkup;
  };

  const ListIcon = () => {
    if (listIcon.length === 0) {
      return null;
    }
    const listMarkup = listIcon.map((item, index) => {
      return (
        <a
          key={index}
          id="w-node-d1ae5635-29ca-110e-1769-5c481dc6355b-0ceea2d8"
          href={item.linkSocialMedia}
          target="_blank"
          rel="noreferrer"
          className={styles.socialicon + " w-inline-block"}
        >
          <img
            src={item.socialMediaIcon}
            loading="lazy"
            alt=""
            className={styles.sIcon}
          />
        </a>
      );
    });
    return listMarkup;
  };
  if (!props.show) {
    return null;
  }
  return (
    <div className={styles.dealerinfomodal} style={{ display: "flex" }}>
      <div className={styles.dealerinfomodalinner}>
        <div
          className={
            parentStyles.wLayoutGrid + " " + styles.dealerinfomodaltitle
          }
        >
          <div
            id="w-node-_5f1daecc-03d5-2480-28a5-fa510546f2f1-0ceea2d8"
            className={styles.dealerbrandblock}
          >
            <img
              src={props.dealershipInfo.logoOriginal}
              loading="lazy"
              alt=""
              className={parentStyles.dealerlogo}
            />
            <h1 className={styles.dealermodaltitle}>
              {props.dealershipInfo.title}
            </h1>
            <a
              href={props.dealerUrl.urlblock}
              target="_blank"
              rel="noreferrer"
              className={
                parentStyles.dealerurl + " " + styles.dealerurlLarge + " large"
              }
            >
              {props.dealerUrl.dealerTitle}
            </a>
          </div>
          <div
            id="w-node-a50ea833-8d83-1b46-8c2e-8ca82f126d70-0ceea2d8"
            className={styles.buttonblock}
          >
            <a
              rel="noreferrer"
              id="w-node-_69b69fdf-61c2-5093-e2dc-05ae52090802-0ceea2d8"
              href={"tel:" + props.dealershipInfo.nrTelContact}
              className={styles.primarybutton + " w-inline-block dealer-info-sales-phone"}
            >
              <img
                src={icon_phone_dark}
                loading="lazy"
                alt=""
                className={styles.primarybuttonicon}
              />
              <div>
                Sales:{" "}
                <PatternFormat
                  value={props.dealershipInfo.nrTelContact}
                  displayType={"text"}
                  format="(###) ###-#####"
                />
              </div>
            </a>
          </div>
        </div>
        <div className={parentStyles.wLayoutGrid + " " + styles.dealerinfogrid}>
          {props.dealershipInfo.dealership?
          <div
            id="w-node-eaaf4efb-076d-1d60-5812-39460388bc7f-0ceea2d8"
            className="dealerimageblock"
          >
            <img
              src={props.dealershipInfo.dealership}
              loading="lazy"
              sizes="100vw"
              srcSet={
                props.dealershipInfo.dealership +
                " 500w, " +
                props.dealershipInfo.dealership +
                " 680w"
              }
              alt=""
              className={styles.dealerimg}
            />
            <p>{props.dealershipInfo.textContent}</p>
          </div>:null}
          <div
            id="w-node-ffe71b88-9e59-36f8-7fcb-f819449f7837-0ceea2d8"
            className={
              parentStyles.wLayoutGrid + " " + styles.dealerinfogridinner
            }
          >
            <div
              id="w-node-d1ae5635-29ca-110e-1769-5c481dc6354b-0ceea2d8"
              className={styles.dealercontactinfo}
            >
              <div className={parentStyles.dealeraddressblock}>
                <div className={parentStyles.ptitle}>
                  {props.dealershipInfo.title}
                </div>
                <p>
                  {props.dealershipInfo.address?.street}
                  <br />
                  {props.dealershipInfo.address?.city},{" "}
                  {props.dealershipInfo.address?.state}{" "}
                  {props.dealershipInfo.address?.zip}
                </p>
                <a
                  href={
                    "https://www.google.com/maps/dir//" +
                    props.dealershipInfo.address?.street.split(" ").join("+") +
                    "+" +
                    props.dealershipInfo.address?.city +
                    "+" +
                    props.dealershipInfo.address?.state +
                    "+" +
                    props.dealershipInfo.address?.zip
                  }
                  target="_blank"
                  rel="noreferrer"
                  className={
                    styles.primarybuttonMt12 +
                    " " +
                    styles.primarybutton +
                    " w-inline-block dealer-info-direction"
                  }
                >
                  <img
                    src={icon_directions}
                    loading="lazy"
                    alt=""
                    className={styles.primarybuttonicon}
                  />
                  <div>Directions</div>
                </a>
              </div>
              <div className={styles.socialiconwrap}>
                <div className={parentStyles.ptitle}>Connect With Us</div>
                <div
                  className={
                    parentStyles.wLayoutGrid + " " + styles.socialicongrid
                  }
                >
                  <ListIcon />
                </div>
              </div>
            </div>
            <div
              id="w-node-_68fee57d-4514-471f-2720-b6f18d6d5a0d-0ceea2d8"
              className={parentStyles.depthourswrap}
            >
              <div className={parentStyles.ptitle}>Department Hours</div>
              <div className={styles.hoursaccwrapinner}>
                <List />
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            parentStyles.closeModalBlock +
            " absolute " +
            parentStyles.closeModalBlockAbsolute
          }
          onClick={() => props.setShowDealerinfomodal(false)}
        >
          <img
            src="https://uploads-ssl.webflow.com/62965a5a5e3432478f065224/62965a5a5e3432694606528c_close%20(20).svg"
            loading="lazy"
            alt=""
            className="close-modal-icon"
          />
        </div>
      </div>
    </div>
  );
}
