import styles from "./Buyersguide.module.css";
import close_20 from "./../../images/close-20.svg";

export function Buyersguide(props) {
  if (!props.show) {
    return null;
  }
  return (
    <div className={styles.originalwarrantymodal}>
      <div className={styles.originalwarrantymodalinner}>
        <div
          data-w-id="4c1bb755-fb92-5c2e-4fc4-1f52edc09338"
          className={styles.closeModalBlock + " " + styles.closeModalBlockAbsolute}
        >
          <img
            src={close_20}
            loading="lazy"
            alt=""
            className="close-modal-icon"
            onClick={() => props.setShowBuyersGuide(false)}
          />
        </div>
        <div className={styles.modalsebullets + " w-richtext"}>
          <h3>original warranty</h3>
          <ul>
            <li>Basic warranty: 48 month/50,000 miles</li>
            <li>Powertrain warranty: 72 month/70,000 miles</li>
            <li>Hybrid/electric components warranty: 96 month/100000 miles</li>
            <li>Corrosion perforation warranty: 60 month/unlimited</li>
            <li>Roadside warranty: 48 month/50,000 miles</li>
          </ul>
        </div>
      </div>
    </div>
  );
}


