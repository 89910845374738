import styles from "./VehiclePrice.module.css";
import parentStyles from "./../home/Home.module.css";
import { useState, useEffect } from "react";

const List = (props) => {
  if (props.data.length === 0) {
    return null;
  }
  const listMarkup = props.data.map((item, index) => {
    return (
      <div
        key={index}
        className={
          styles.pricebullet 
        }
      >
        <div>
          [{item.code}] {item.description}
        </div>
        <div className="optionprice">{props.dollarUS.format(item.msrp)}</div>
      </div>
    );
  });
  return listMarkup;
};

const List2 = (props) => {
  if (props.data.length === 0) {
    return null;
  }
  const listMarkup = props.data.map((item, index) => {
    const subList = item.options[0].options.map((element, index2) => {
      return (
        <li key={index2}>
          [{element.code}] {element.description}
        </li>
      );
    });

    return (
      <div key={index} className={styles.packageblock}>
        <div className={styles.packagetitleblock}>
          <div className={styles.optionlabel}>
            {item.options[0].code} {item.name}
          </div>
          <div className="optionprice">
            {props.dollarUS.format(item.options[0].msrp)}
          </div>
        </div>
        <div className={styles.packageitemlist + " w-richtext"}>
          <ul>{subList}</ul>
        </div>
      </div>
    );
  });
  return listMarkup;
};

const TotalInstalledOptions = (props) => {
  if (!props.totalOptions) {
    return null;
  }
  return (
    <div
      className={styles.pricesectitle2 + " " + styles.pricesectitleMt12}
    >
      <h3 className={styles.nostroke}>Total Installed Options</h3>
      <h3 className={styles.nostrokePrice}>
        {props.dollarUS.format(props.totalOptions)}
      </h3>
    </div>
  )
}

const PackageOptions = (props) => {
  if(!props.packagesList.length) {
    return null;
  }
  return (
    <div className={styles.optionssecwrap}>
      <h3 className={styles.smallpricesecheading}>
        Installed Package Options
      </h3>
      <List2 data={props.packagesList} dollarUS={props.dollarUS} />
      <div
        className={styles.pricesectitle + " " + styles.pricesectitleMt12}
      >
        <h3 className={styles.nostroke2}>
          Total Installed PACKAGE Options
        </h3>
        <h3 className={styles.nostrokePrice}>
          {props.dollarUS.format(props.totalPackages)}
        </h3>
      </div>
    </div>
  )
}

const PriceLabelComponent = (props) => {
  if (props.finalPriceChoice === 'ourPrice') {
    return 'OUR PRICE';
  }
  return 'ORIGINAL TOTAL MSRP';
}

const TotalMsrp = (props) => {
  // only show TOTAL ORIGINAL MSRP when the choice is totalMsrp from settings
  if (props.finalPriceChoice !== 'totalMsrp') {
    return null;
  }

  return (
    <div className={styles.dealerpricingblock}>
      <PriceLabelComponent
        finalPriceChoice={props.finalPriceChoice}
      />
      <div>{props.dollarUS.format(props.retailValue - props.discount)}</div>
    </div>
  );
}

const DealerPricingComponent = (props) => {
  // only show TOTAL ORIGINAL MSRP when the choice is ourPrice from settings
  if (props.finalPriceChoice !== 'ourPrice') {
    return null;
  }

  // now you have two choices, if we have discount - displat the yellow block
  if (props.discount && props.discount !== 0) {
    return (
      <div className={styles.optionssecwrap + ' ' + styles.dealerpricing}>
        <div className={styles.optionblock + ' ' + styles.lightborder}>
          <div className={styles.optionlabel}>MSRP/Retail Price</div>
          <div className={styles.optionprice}>{props.dollarUS.format(props.retailValue)}</div>
        </div>
        <div className={styles.optionblock + ' ' + styles.last}>
          <div className={styles.optionlabel}>Dealer Discount</div>
          <div className={styles.optionprice}>{props.dollarUS.format(props.discount)}</div>
        </div>
        <div className={styles.dealerpricingblock}>
          <div>
            <PriceLabelComponent
              finalPriceChoice={props.finalPriceChoice}
            />
          </div>
          <div>{props.dollarUS.format(props.retailValue - props.discount)}</div>
        </div>
      </div>
    )
  }

  // otherwise just show a big black button without the yellow block
  return (
    <div className={styles.dealerpricingblock}>
      <PriceLabelComponent
        finalPriceChoice={props.finalPriceChoice}
      />
      <div>{props.dollarUS.format(props.retailValue - props.discount)}</div>
    </div>
  );
}

export function VehiclePrice(props) {
  const [optionsList, setOptionsList] = useState([]);
  const [packagesList, setPackagesList] = useState([]);
  const dollarUS = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const sortOptionsByMSRP = (unsortedOptionsList) => {
    const msrpGraterThanZero = [];
    const msrpEqualToZero = [];
    const msrpLessThanZero = [];

    unsortedOptionsList.forEach((item) => {
      if (item.msrp > 0) {
        msrpGraterThanZero.push(item);
      } else if (item.msrp === 0) {
        msrpEqualToZero.push(item);
      } else {
        msrpLessThanZero.push(item);
      }
    });
    return msrpEqualToZero.concat(msrpGraterThanZero, msrpLessThanZero);
  }

  const sortPackagesByMSRP = (unsortedPackagesList) => {
    const msrpGraterThanZero = [];
    const msrpEqualToZero = [];

    unsortedPackagesList.forEach((item) => {
      if (item.options[0].msrp > 0) {
        msrpGraterThanZero.push(item);
      } else {
        msrpEqualToZero.push(item);
      }
    });
    return msrpEqualToZero.concat(msrpGraterThanZero);
  }

  useEffect(() => {
    const sortedByMSRP = sortOptionsByMSRP(props.options);
    setOptionsList(sortedByMSRP);
    console.log(props.packages)
    const packagesSortedByMSRP = sortPackagesByMSRP(props.packages);
    setPackagesList(packagesSortedByMSRP);
  }, [props.options, props.packages]);

  return (
    <div
      id="w-node-_49b1eee8-6b0d-a15c-f47d-a581408dad3d-0ceea2d8"
      className={parentStyles.column}
    >
      <h2 className={parentStyles.sectiontitle} style={{backgroundColor: props.accentColor}}>VEHICLE PRICE</h2>
      <div className={styles.pricingcol}>
        <div className="vehiclepricesec">
          <div className={styles.lineitemtotalblock}>
            <h3 className={styles.nostroke}>base msrp</h3>
            <h3 className={styles.nostrokePrice}>{dollarUS.format(props.msrp)}</h3>
          </div>
          <div className={styles.optionssecwrap}>
            <h3 className={styles.smallpricesecheading}>Installed Options</h3>
            <List data={optionsList} dollarUS={dollarUS} />
            <TotalInstalledOptions 
              totalOptions={props.totalOptions}
              dollarUS={dollarUS}
            />
          </div>
          <PackageOptions 
            packagesList={packagesList}
            dollarUS={dollarUS}
            totalPackages={props.totalPackages}
          />
          <div
            className={
              styles.pricesectitle +
              " " +
              styles.pricesectitleMt12 +
              " " +
              styles.optionssecwrap
            }
          >
            <h3 className={styles.nostroke}>Delivery FEE</h3>
            <h3 className={styles.nostrokePrice}>
              {dollarUS.format(props.deliveryFee)}
            </h3>
          </div>
          <TotalMsrp
            discount={props.discount}
            retailValue={props.retailValue}
            totalOriginalMsrp={props.totalOriginalMsrp}
            dollarUS={dollarUS}
            finalPriceChoice={props.finalPriceChoice}
          />
          <DealerPricingComponent
            retailValue={props.retailValue}
            discount={props.discount}
            dollarUS={dollarUS}
            finalPriceChoice={props.finalPriceChoice}
          />
        </div>
      </div>
    </div>
  );
}
