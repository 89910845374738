import styles from "./StandardEquipment.module.css";
import parentStyles from "./../home/Home.module.css";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setShowLoader } from "../home/homeSlice";

export function StandardEquipment(props) {
  const [modifiedListData, setModifiedListData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    // Create an empty list to store "HE>>>" and "LI>>>" entries.
    let newList = [];
    // Iterate through categories and items to build the list.
    props.categories.forEach((cat, index) => {
      // Add "HE>>>" entry for each category.
      newList.push("HE>>>" + cat.name);
      // Add "LI>>>" entries for each item within the category.
      cat.items.forEach((ctitem) => {
        newList.push("LI>>>" + ctitem);
      });
    });
  
    // Log the new created list 
    // console.log('NewList: ', newList);
  
    // Define the number of columns to distribute the data.
    const columnCount = 3;
    // Create an array of lists, each representing a column.
    const lists = Array.from({ length: columnCount }, () => []);
  
    // Initialize the current column index.
    let currentColumnIndex = 0;
  
    // Define the maximum number of items per column.
    const maxItemsPerColumn = Math.ceil(newList.length / columnCount);
  
    // Iterate through the newList to distribute "HE>>>" and "LI>>>" entries into columns.
    for (let i = 0; i < newList.length; i++) {
      if (newList[i].startsWith("HE>>>")) {
        // Check if the middle column has more than 4 "HE>>>" entries.
        if (currentColumnIndex === 1 && lists[1].filter(item => item.startsWith("HE>>>")).length > 3) {
          currentColumnIndex++;
        }
        // Ensure that the current column index stays within limits.
        currentColumnIndex = currentColumnIndex % columnCount;
        // Push the "HE>>>" entry into the current column.
        lists[currentColumnIndex].push(newList[i]);
        // Move to the next column if the current one is full.
        if (lists[currentColumnIndex].length >= maxItemsPerColumn) {
          currentColumnIndex++;
          // Ensure that the current column index stays within limits.
          if (currentColumnIndex >= columnCount) {
            currentColumnIndex = 0;
          }
        }
      } else if (newList[i].startsWith("LI>>>")) {
        // Push the "LI>>>" entry into the current column.
        lists[currentColumnIndex].push(newList[i]);
      }
    }

    // Check if the last entry in each column is a "HE>>>" and move it to the next column if so.
    for (let i = 0; i < columnCount; i++) {
      const lastEntry = lists[i][lists[i].length - 1];
      if (lastEntry && lastEntry.startsWith("HE>>>")) {
        lists[i].pop();
        const nextColumnIndex = (i + 1) % columnCount;
        lists[nextColumnIndex].unshift(lastEntry);
      }
    }
    // console.log('Lists: ', lists);
    // Update the state variable modifiedListData with the distributed data.
    setModifiedListData(lists);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.categories]);

  useEffect(() => {
    if (!modifiedListData.length) {
      return;
    }
    dispatch(setShowLoader(false));

  }, [modifiedListData, dispatch]);

  const List = (props) => {
    if (modifiedListData.length === 0) {
      return null;
    }

    const listDataMarkup = modifiedListData.map((item, index) => {
      // eslint-disable-next-line array-callback-return
      const categoryDivs = item.map((element, indexElement) => {
        if (element.startsWith("HE")) {
          const elementArray = element.split(">>>");
          const categoryTitle = elementArray[1];
  
          const liElements = [];
          let liCount = 0; // counter for element number <li>
          let entireListOfElements = [];
          for (let i = indexElement + 1; i < item.length; i++) {

            if (item[i].startsWith("LI")) {

              const liElementArray = item[i].split(">>>");
              entireListOfElements.push(liElementArray[1]);

              if (liCount < 10) { // Check max number of elements <li>
                liElements.push(<li key={"li-" + i}>{liElementArray[1]}</li>);
                liCount++;
              }
            } else {
              break;
            }
          }

          return (
            <div key={"category-" + indexElement} className={styles.categoryWrapper}>
              <h3 className={styles.categoryTitle}>{categoryTitle}</h3>
              <ul className={styles.ul}>
                {liElements}
              </ul>

              {liCount >= 10 ? (
                <div
                className={styles.seviewalltrigger}
                onClick={(event) => props.updateContentAndOpenEquipmentModal(categoryTitle, entireListOfElements)}
              >
                View All
              </div>
              ) : null}
            </div>
          );
        }
      });
  
      return (
        <div key={"div-" + index} className="standardequipblock w-richtext">
          {categoryDivs}
        </div>
      );
    });
  
    return listDataMarkup;
  };
  
  return (
    <div
      className={parentStyles.column}
    >
      <h2 className={parentStyles.sectiontitle} style={{backgroundColor: props.accentColor}}>STANDARD EQUIPMENT</h2>

      <div className={styles.standardequipcol}>
        <List 
          updateContentAndOpenEquipmentModal = {(categoryTitle, categoryList) => props.updateContentAndOpenEquipmentModal(categoryTitle, categoryList)}
        />
      </div>
    </div>
  );
}
