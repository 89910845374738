import styles from "./Warning.module.css";


export function Warning(props) {
  return (
    <>
      <section className={styles.warningsection}>
        <div className={styles.nowindowstickerwarning}>
          <div className={styles.ymmt}>{ props.year } { props.make } { props.model} { props.trim }</div>
          <p>There is no Interactive Window Sticker available for this vehicle. To request one, please contact the dealership at: <a href="tel:+7021231234" className={styles.phonelink}>(702) 123-1234</a><br/></p>
        </div>
      </section>
    </>
  );
}
