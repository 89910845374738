import styles from "./Home.module.css";
import { Header } from "./../header/Header";
import { StandardEquipment } from "./../standard_equipment/StandardEquipment";
import { VehiclePrice } from "./../vehicle_price/VehiclePrice";
import { Actions } from "./../actions/Actions";
import { Equipmentmodal } from "./../equipment_modal/Equipmentmodal";
import { Buyersguide } from "./../buyersguide/Buyersguide";
import { Warranty } from "./../warranty/Warranty";
import { Footer } from "./../footer/Footer";
import { Dealerinfomodal } from "./../dealer_info_modal/Dealerinfomodal";
import { Schedulevisitmodal } from "./../schedule_visit_modal/Schedulevisitmodal";
import { Sharemodal } from "./../share_modal/Sharemodal";
import { Shareconfirm } from "./../share_confirm/Shareconfirm";
import { useParams } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchJsonData,
  setSubdomain,
  setVin,
  data,
  showLoader,
  error,
  userType,
  verifyUserTypeData,
  getPrintPdf
} from "./homeSlice";
import { Loader } from "./../loader/Loader";
import { Warning } from "./../warning/Warning";
import { LeadForm } from "../lead_form/LeadForm";
import { Helmet } from "react-helmet";

const LoaderOrWarning = (props) => {
  if (props.showLoaderLocal && !props.errorLocal) {
    return <Loader images={props.images} />;
  }
  if (props.errorLocal) {
    return <Warning
      year={props.data.year}
      make={props.data.make}
      model={props.data.model}
      trim={props.data.trim}
    />;
  }
  return;
}

const Page = (props) => {
  if (Object.keys(props.data).length === 0 || props.data.type === 'error') {
    return null;
  }
  return (
    <div className={styles.body}>
      <div className={styles.mainwrapper + " wf-section"}>
        <Header
          year={props.data.year}
          make={props.data.make}
          model={props.data.model}
          trim={props.data.trim}
          vin={props.data.vin}
          engine={props.data.engine}
          transmission={props.data.transmission}
          interiorColor={props.data.interiorColor}
          exteriorColor={props.data.exteriorColor}
          cityMpg={props.data.cityMpg}
          highwayMpg={props.data.highwayMpg}
          images={props.data.images}
          dealerUrl={props.data.dealerUrl}
          nrTelContact={props.data.dealershipInfo.nrTelContact}
          mpgDescription={props.data.mpgDescription}
          mileage={props.data.mileage}
          accentColor={props.data.accentColor}
          isElectric={props.data.isElectric}
          combinedMpg={props.data.combinedMpg}
        />
        <div className={styles.wLayoutGrid + " " + styles.equippricingwrap}>
          <StandardEquipment
            categories={props.data.equipment.categories}
            updateContentAndOpenEquipmentModal={(categoryTitle, categoryList) =>
              props.updateContentAndOpenEquipmentModal(categoryTitle, categoryList)
            }
            accentColor={props.data.accentColor}
          />
          <VehiclePrice
            options={props.data.options.items}
            totalOptions={props.data.options.total}
            msrp={props.data.msrp}
            packages={props.data.packages}
            totalPackages={props.data.totalPackages}
            deliveryFee={props.data.deliveryFee}
            totalOriginalMsrp={props.data.totalOriginalMsrp}
            retailValue={props.data.retailValue}
            discount={props.data.discount}
            accentColor={props.data.accentColor}
            finalPriceChoice={props.data.finalPriceChoice}
          />
        </div>
        <Actions
          userType={props.userType}
          warrantyArray={props.warrantyArray}
          nrTelContact={props.data.dealershipInfo.nrTelContact}
          categories={props.data.equipment.categories}
          printLoading={props.printLoading}

          setShowDealerinfomodal={(value) =>
            props.setShowDealerinfomodal(value)
          }
          setShowSchedulevisitmodal={(value) =>
            props.setShowSchedulevisitmodal(value)
          }
          setShowSharemodal={(value) => props.setShowSharemodal(value)}
          setShowBuyersGuide={(value) => props.setShowBuyersGuide(value)}
          setShowWarranty={(value) => props.setShowWarranty(value)}
          handlePrint={(type, standardEquipmentModified) => props.handlePrint(type, standardEquipmentModified)}
        />
        {/* <Sharewindowsticker
          setShowSharemodal={(value) => props.setShowSharemodal(value)}
        /> */}
      </div>
      <Dealerinfomodal
        show={props.showDealerinfomodal}
        socialMedia={props.data.socialMedia}
        dealershipInfo={props.data.dealershipInfo}
        setShowDealerinfomodal={(value) => props.setShowDealerinfomodal(value)}
        dealerUrl={props.data.dealerUrl}
        shopData={props.data.shopData}
      />
      <Schedulevisitmodal
        show={props.showSchedulevisitmodal}
        dealershipInfo={props.data.dealershipInfo}
        mainDealershipId={props.data.mainDealershipId}
        listHours={props.data.scheduleHours}
        setShowSchedulevisitmodal={(value) =>
          props.setShowSchedulevisitmodal(value)
        }
      />
      <Sharemodal
        show={props.showSharemodal}
        mainDealershipId={props.data.mainDealershipId}
        setShowSharemodal={(value) => props.setShowSharemodal(value)}
        setShowShareconfirm={(value) => props.setShowShareconfirm(value)}
      />
      <Shareconfirm
        show={props.showShareconfirm}
        setShowShareconfirm={(value) => props.setShowShareconfirm(value)}
      />
      <Footer
        disclaimerTitle={props.data.dealershipInfo.disclaimerTitle}
        disclaimerText={props.data.dealershipInfo.disclaimerText}
        poweredBy={props.data.dealershipInfo.poweredBy}
        urlQuotible={props.data.dealershipInfo.urlQuotible}
      />
      <Equipmentmodal
        show={props.showEquipmentmodal}
        selectedCategoryTitle={props.selectedCategoryTitle}
        selectedCategoryList={props.selectedCategoryList}
        setShowEquipmentmodal={(value) => props.setShowEquipmentmodal(value)}
      />
      <Buyersguide
        show={props.showBuyersGuide}
        setShowBuyersGuide={(value) => props.setShowBuyersGuide(value)}
      />
      <Warranty
        warrantyArray={props.warrantyArray}
        show={props.showWarranty}
        setShowWarranty={(value) => props.setShowWarranty(value)}
      />
    </div>
  );
};

export function Home() {
  const { inputSubdomain, inputVin } = useParams();
  const dispatch = useDispatch();

  const dataLocal = useSelector(data);
  const showLoaderLocal = useSelector(showLoader);
  const errorLocal = useSelector(error);
  const userTypeLocal = useSelector(userType);
  const [showSharemodal, setShowSharemodal] = useState(false);
  const [showShareconfirm, setShowShareconfirm] = useState(false);
  const [showEquipmentmodal, setShowEquipmentmodal] = useState(false);
  const [showSharewindowsticker, setShowSharewindowsticker] = useState(false);

  const [selectedCategoryTitle, setSelectedCategoryTitle] = useState();
  const [selectedCategoryList, setSelectedCategoryList] = useState();

  const [showDealerinfomodal, setShowDealerinfomodal] = useState(false);
  const [showSchedulevisitmodal, setShowSchedulevisitmodal] = useState(false);
  const [showPrice, setShowPrice] = useState(false);
  const [showBuyersGuide, setShowBuyersGuide] = useState(false);
  const [showWarranty, setShowWarranty] = useState(false);
  const [warrantyArray, setWarrantyArray] = useState([]);

  const [printLoading, setPrintLoading] = useState({ mobile: false, desktop: false });

  const [displayLeadForm, setDisplayLeadForm] = useState(false);

  const updateContentAndOpenEquipmentModal = (categoryTitle, categoryList) => {
    setSelectedCategoryTitle(categoryTitle);
    setSelectedCategoryList(categoryList);
    setShowEquipmentmodal(true);
  };

  useEffect(() => {
    dispatch(setSubdomain(inputSubdomain));
  }, [dispatch, inputSubdomain]);

  useEffect(() => {
    dispatch(setVin(inputVin));
  }, [dispatch, inputVin]);

  useEffect(() => {
    dispatch(
      fetchJsonData({
        subdomain: inputSubdomain,
        vin: inputVin,
      })
    );
  }, [dispatch, inputSubdomain, inputVin]);

  const sendDataToDataLayer = useCallback(() => {
    const gaData = {
      'event': 'quote_data',
      'dealership': inputSubdomain,
      'dealership_brand': dataLocal.make,
      'ymmt': dataLocal.year + " " + dataLocal.make + " " + dataLocal.model + " " + dataLocal.trim,
      'vin_num': dataLocal.vin,
      'user_type': userTypeLocal,
      'productType': 'window_sticker',
      'dealership_id': dataLocal.mainDealershipId
    };

    //adding datalayer event
    if (typeof window['dataLayer'] == 'undefined') {
      window['dataLayer'] = [];
    }

    window['dataLayer'].push(gaData);

    console.log("Quote Data event sent to data layer: ", gaData);
  }, [dataLocal.make, dataLocal.model, dataLocal.trim, dataLocal.vin, dataLocal.year, inputSubdomain, userTypeLocal, dataLocal.mainDealershipId]);

  useEffect(() => {
    if (dataLocal?.equipment?.categories?.length > 0) {
      const foundCategory = dataLocal.equipment.categories.find(
        category => category.name.toLowerCase() === 'original warranty'
      );
      if (foundCategory && foundCategory.items) {
        setWarrantyArray(foundCategory.items);
      }
    }
    if (dataLocal && dataLocal.vin && dataLocal.type !== 'error') {
      loadGAScript();
      sendDataToDataLayer();
    }
    if(dataLocal && dataLocal.displayLeadForm) {
      setDisplayLeadForm(dataLocal.displayLeadForm);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLocal]);

  useEffect(() => {
    const currentURL = window.location.href;
    // URL Object
    const url = new URL(currentURL);
    let urlSearch = url.search;

    // Search & Hash Example '?h=abc12345'
    const savedHash = urlSearch.substring(urlSearch.indexOf('=') + 1);
    console.log(savedHash);

    // Clean up the search query
    url.search = '';
    // URL after cleaning up the search query
    const modifiedURL = url.href;
    // Update the URL
    window.history.replaceState({}, document.title, modifiedURL);

    dispatch(
      verifyUserTypeData({
        hash: savedHash,
        vin: inputVin,
        dealership: inputSubdomain
      })
    );
  }, [dispatch, inputVin, inputSubdomain]);

  const handlePrint = async (type, standardEquipmentModified) => {

    setPrintLoading(previousState => ({
      ...previousState,
      [type]: true,
    }));
    let dataToSend = {};
    let header = {
      year: dataLocal.year,
      make: dataLocal.make,
      model: dataLocal.model,
      trim: dataLocal.trim,
      vin: dataLocal.vin,
      engine: dataLocal.engine,
      transmission: dataLocal.transmission,
      interiorColor: dataLocal.interiorColor,
      exteriorColor: dataLocal.exteriorColor,
      cityMpg: dataLocal.cityMpg,
      highwayMpg: dataLocal.highwayMpg,
      images: dataLocal.images,
      dealerUrl: dataLocal.dealerUrl,
      nrTelContact: dataLocal.dealershipInfo.nrTelContact,
      mpgDescription: dataLocal.mpgDescription,
      mileage: dataLocal.mileage,
      isElectric: dataLocal.isElectric,
      combinedMpg: dataLocal.combinedMpg,
    };

    let vehiclePrice = {
      showPrice: dataLocal.showPrice,
      options: dataLocal.options.items,
      totalOptions: dataLocal.options.total,
      msrp: dataLocal.msrp,
      packages: dataLocal.packages,
      totalPackages: dataLocal.totalPackages,
      deliveryFee: dataLocal.deliveryFee,
      totalOriginalMsrp: dataLocal.totalOriginalMsrp,
      retailValue: dataLocal.retailValue,
      discount: dataLocal.discount
    };

    dataToSend = {
      route: 'print',
      data: {
        standardEquipment: standardEquipmentModified,
        header: header,
        vehiclePrice: vehiclePrice,
        finalPriceChoice: dataLocal.finalPriceChoice
      },
      vin: inputVin,
      dealership: inputSubdomain
    };
    const result = await dispatch(getPrintPdf(dataToSend));
    if (!result?.payload?.path) {
      console.log('something went wrong', result);
      setPrintLoading(previousState => ({
        ...previousState,
        [type]: false,
      }
      ));
      return;
    }
    const pdfWindow = window.open();
    pdfWindow.location.href = result.payload.path;
    setPrintLoading(previousState => ({
      ...previousState,
      [type]: false,
    }
    ));
  }

  const loadGAScript = () => {
    const w = window;
    const d = document;
    const s = 'script';
    const l = 'dataLayer';
    const i = 'GTM-TSQ2B3L';
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });
    // eslint-disable-next-line eqeqeq
    var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    if (f.parentNode) {
      f.parentNode.insertBefore(j, f);
    }
    console.log('GTM Loaded');
  }

  return (
    <>
      <Helmet>
        <title>Window Sticker</title>
      </Helmet>
      <LoaderOrWarning
        showLoaderLocal={showLoaderLocal}
        errorLocal={errorLocal}
        images={dataLocal.images}
        data={dataLocal}
      />
      <LeadForm
        images={dataLocal.images}
        displayLeadForm={displayLeadForm}
        setDisplayLeadForm={(value) => setDisplayLeadForm(value)}
      />
      <Page
        data={dataLocal}
        userType={userTypeLocal}
        showSharemodal={showSharemodal}
        showEquipmentmodal={showEquipmentmodal}
        showSharewindowsticker={showSharewindowsticker}
        showShareconfirm={showShareconfirm}
        showDealerinfomodal={showDealerinfomodal}
        showSchedulevisitmodal={showSchedulevisitmodal}
        showBuyersGuide={showBuyersGuide}
        showWarranty={showWarranty}
        showPrice={showPrice}
        printLoading={printLoading}
        setShowDealerinfomodal={(value) => setShowDealerinfomodal(value)}
        setShowSchedulevisitmodal={(value) => setShowSchedulevisitmodal(value)}
        setShowBuyersGuide={(value) => setShowBuyersGuide(value)}
        setShowWarranty={(value) => setShowWarranty(value)}
        setShowPrice={(value) => setShowPrice(value)}
        setShowSharemodal={(value) => setShowSharemodal(value)}
        setShowEquipmentmodal={(value) => setShowEquipmentmodal(value)}
        setShowSharewindowsticker={(value) => setShowSharewindowsticker(value)}
        setShowShareconfirm={(value) => setShowShareconfirm(value)}
        selectedCategoryTitle={selectedCategoryTitle}
        selectedCategoryList={selectedCategoryList}
        updateContentAndOpenEquipmentModal={(categoryTitle, categoryList) => updateContentAndOpenEquipmentModal(categoryTitle, categoryList)}
        warrantyArray={warrantyArray}
        setPrintLoading={(value) => setPrintLoading(value)}
        handlePrint={(type, standardEquipmentModified) => handlePrint(type, standardEquipmentModified)}
      />
    </>
  );
}
