import styles from "./ScheduleConfirm.module.css";
import parentStyles from "../schedule_visit_modal/Schedulevisitmodal.module.css";
import icon_directions from "./../../images/icon-directions.svg";
import location_1 from "./../../images/location-1.svg";
import calendar from "./../../images/calendar3.svg";
import * as dateFns from "date-fns";
import { useState } from "react";

export function ScheduleConfirm(props) {
  const [dataShow] = useState(props.selectedDate);

  const RenderHours = () => {
    const dateFormat = "EEEE, MMMM do, yyyy h:mmaaa";
    const date = dateFns.format(new Date(dataShow), dateFormat);

    return date;
  };

  return (
    <div className={parentStyles.leadform + " " + parentStyles.wForm}>
      <div className={styles.leadformsuccess + " " + styles.wFormDone}>
        <div
          id="w-node-c3338700-209d-d14d-a70d-e194ee0a24e4-0ceea2d8"
          className={styles.confirmationwrap}
        >
          <div className={styles.useractiontitleblock}>
            <div className={styles.smuseractiontitle2}>
              Congratulations, your visit has been scheduled!
            </div>
          </div>
          <div className={styles.wLayoutGrid + " " + styles.tddetailsgrid}>
            <div
              id="w-node-c3338700-209d-d14d-a70d-e194ee0a24e9-0ceea2d8"
              className={styles.tddetailscol + " first"}
            >
              <div className={styles.tdinfoblock}>
                <div className={styles.tddetailstitle}>
                  <img
                    src={calendar}
                    loading="lazy"
                    alt=""
                    className={styles.tddetailsicon}
                  />
                  <div>
                    <RenderHours />
                  </div>
                </div>
                <div className={styles.tdinfoblockinner}></div>
              </div>
            </div>
            <div
              id="w-node-c3338700-209d-d14d-a70d-e194ee0a24f0-0ceea2d8"
              className={styles.tddetailscol}
            >
              <div className={styles.tdinfoblock}>
                <img
                  src={location_1}
                  loading="lazy"
                  alt=""
                  className={styles.tddetailsicon}
                />
                <div className={styles.tdinfoblockinner + " address"}>
                  <div className={styles.tdaddtitle}>{props.dealershipInfo.title}</div>
                  <div>
                    {props.dealershipInfo.address.street}
                    <br />
                    {props.dealershipInfo.address.city}, {props.dealershipInfo.address.state} {props.dealershipInfo.address.zip}
                  </div>
                </div>
                <a
                  href={
                    "https://www.google.com/maps/dir//" +
                    props.dealershipInfo.address.street.split(" ").join("+") +
                    "+" +
                    props.dealershipInfo.address.city +
                    "+" +
                    props.dealershipInfo.address.state +
                    "+" +
                    props.dealershipInfo.address.zip
                  }
                  target="_blank"
                  rel="noreferrer"
                  className={
                    styles.primarybutton + " " + styles.mt24 + " w-inline-block"
                  }
                >
                  <img
                    src={icon_directions}
                    loading="lazy"
                    alt=""
                    className={styles.primarybuttonicon}
                  />
                  <div>Directions</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.wFormFail}>
        <div>Oops! Something went wrong while submitting the form.</div>
      </div>
    </div>
  );
}
