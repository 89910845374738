import React from 'react';
import { Home } from './features/home/Home';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LinkPage } from './features/link_page/LinkPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/:inputSubdomain/:inputVin" element={<Home />} />
        <Route path="/:dealership/:vin/link-page" element={<LinkPage />} />
      </Routes>
    </Router>
  );
}

export default App;
