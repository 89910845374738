import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchLinksAPI } from './linkPageAPI';

const initialState = {
  wsLink: "",
  vehicleDetailPageUrl: "",
  dealershipWebsiteUrl: "",
  dealershipNewInventoryUrl: "",
  dealershipPreOwnedInventoryUrl: "",
  accentColor: "",
  dealershipLogo: "",
  accessWebsiteFromWs: false
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchJsonData(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const fetchLinksThunk = createAsyncThunk(
  'linkPage/fetchLinks',
  async (payload) => {
    console.log('payload: ', payload);
    const response = await fetchLinksAPI(payload);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const linkPageSlice = createSlice({
  name: "linkPage",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setWsLink: (state, action) => {
      state.wsLink = action.payload;
    },
    setvehicleDetailPageUrl: (state, action) => {
      state.vehicleDetailPageUrl = action.payload;
    },
    setdealershipWebsiteUrl: (state, action) => {
      state.dealershipWebsiteUrl = action.payload;
    },
    setdealershipNewInventoryUrl: (state, action) => {
      state.dealershipNewInventoryUrl = action.payload;
    },
    setdealershipPreOwnedInventoryUrl: (state, action) => {
      state.dealershipPreOwnedInventoryUrl = action.payload;
    },
    setAccentColor: (state, action) => {
      state.accentColor = action.payload;
    },
    setDealershipLogo: (state, action) => {
      state.dealershipLogo = action.payload;
    },
    setAccessWebsiteFromWs: (state, action) => {
      state.accessWebsiteFromWs = action.payload;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchLinksThunk.fulfilled, (state, action) => {
        console.log('action', action);
        state.wsLink = action.payload.wsLink;
        state.vehicleDetailPageUrl = action.payload.vehicleDetailPageUrl;
        state.dealershipWebsiteUrl = action.payload.dealershipWebsiteUrl;
        state.dealershipNewInventoryUrl = action.payload.dealershipNewInventoryUrl;
        state.dealershipPreOwnedInventoryUrl = action.payload.dealershipPreOwnedInventoryUrl;
        state.accentColor = action.payload.accentColor;
        state.dealershipLogo = action.payload.dealershipLogo;
        state.accessWebsiteFromWs = action.payload.accessWebsiteFromWs;
      })
      .addCase(fetchLinksThunk.rejected, (state, action) => {
        console.error("Failed to fetch links:", action.payload);
      });
  }
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const { setWsLink, setvehicleDetailPageUrl, setdealershipWebsiteUrl, setdealershipNewInventoryUrl, setdealershipPreOwnedInventoryUrl, setAccentColor, setDealershipLogo, setAccessWebsiteFromWs } = linkPageSlice.actions;

export const wsLink = (state) => state.linkPage.wsLink;
export const vehicleDetailPageUrl = (state) => state.linkPage.vehicleDetailPageUrl;
export const dealershipWebsiteUrl = (state) => state.linkPage.dealershipWebsiteUrl;
export const dealershipNewInventoryUrl = (state) => state.linkPage.dealershipNewInventoryUrl;
export const dealershipPreOwnedInventoryUrl = (state) => state.linkPage.dealershipPreOwnedInventoryUrl;
export const accentColor = (state) => state.linkPage.accentColor;
export const dealershipLogo = (state) => state.linkPage.dealershipLogo;
export const accessWebsiteFromWs = (state) => state.linkPage.accessWebsiteFromWs;
// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default linkPageSlice.reducer;
