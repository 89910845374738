import styles from "./Calendar.module.css";
import parentStyles from "../schedule_visit_modal/Schedulevisitmodal.module.css";
import grandParentStyles from "../home/Home.module.css";
import { useState } from "react";
import arrowLeft from "./../../images/arrow_left.svg";
import arrowRight from "./../../images/arrow_right.svg";
import * as dateFns from "date-fns";

export function Calendar(props) {
  const [shownMonth, setShownMonth] = useState(new Date());

  const { selectedDate, setSelectedDate } = props;
  const nextMonth = () => {
    setShownMonth(dateFns.addMonths(shownMonth, 1));
  };

  const prevMonth = () => {
    const monthToLoad = dateFns.subMonths(shownMonth, 1);
    const currentMonth = new Date();
    if (
      dateFns.format(monthToLoad, "yyyy-MM-dd") <
      dateFns.format(currentMonth, "yyyy-MM-dd")
    ) {
      return;
    }
    setShownMonth(monthToLoad);
  };

  const RenderHoursHeader = () => {
    const dateFormat = "E, MMM dd, yyyy";
    const date = dateFns.format(new Date(selectedDate), dateFormat);

    return date;
  };

  const RenderHeader = () => {
    const dateFormat = "MMMM yyyy";

    return (
      <div className={styles.datepickerMonthBlock}>
        <div>{dateFns.format(new Date(shownMonth), dateFormat)}</div>

        <div>
          <img
            src={arrowLeft}
            alt=""
            className={styles.datepickerArrow+" "+grandParentStyles.whiteBackgroundColor}
            onClick={prevMonth}
          />
          <img
            src={arrowRight}
            alt=""
            className={styles.datepickerArrow+" "+grandParentStyles.whiteBackgroundColor}
            onClick={nextMonth}
          />
        </div>
      </div>
    );
  };

  const RenderDays = () => {
    const dateFormat = "E";
    const days = [];
    let startDate = dateFns.startOfWeek(shownMonth);
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className={styles.datepickerDayOfWeek} key={i}>
          {dateFns.format(dateFns.addDays(startDate, i), dateFormat)}
        </div>
      );
    }
    return days;
  };

  const RenderCells = () => {
    const monthStart = dateFns.startOfMonth(shownMonth);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(monthStart);
    const endDate = dateFns.endOfWeek(monthEnd);
    const rows = [];
    let days = [];
    let day = startDate;
    const today = dateFns.startOfDay(new Date());
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        days.push(
          today > day ? (
            <RenderCellsPast day={day} key={i} />
          ) : (
            <RenderCellsDay day={day} key={i} />
          )
        );
        day = dateFns.addDays(day, 1);
      }
      rows.push(days);
      days = [];
    }
    return rows;
  };

  const RenderCellsPast = (props) => {
    const formattedDate = dateFns.format(props.day, "d");
    return (
      <div
        className={
          styles.datepickerDay + " " + styles.datepickerDayPast + " past"
        }
      >
        {formattedDate}
      </div>
    );
  };

  const RenderCellsDay = (props) => {
    const formattedDate = dateFns.format(props.day, "d");
    return (
      <div
        className={
          dateFns.format(selectedDate, "yyyy-MM-dd") ===
          dateFns.format(props.day, "yyyy-MM-dd")
            ? styles.datepickerDay +
              " " +
              styles.datepickerDayChosen +
              " datepicker-day chosen"
            : styles.datepickerDay
        }
        onClick={() => selectDate(props.day)}
      >
        {formattedDate}
      </div>
    );
  };

  const selectDate = (day) => {
    setSelectedDate(day);
  };

  const HoursList = () => {
    if (!props.listHours || props.listHours.length === 0) {
      return null;
    }

    const listMarkup = props.listHours.map((item, index) => {
      return item.disabled ? (
        <DisabledTime hour={item.hour} index={index} key={index} />
      ) : item.selected || item.hour === props.selectedHour ? (
        <SelectedTime hour={item.hour} index={index} key={index} />
      ) : (
        <DefaultTime hour={item.hour} index={index} key={index} />
      );
    });
    return listMarkup;
  };

  const DisabledTime = (props) => {
    return (
      <button
        key={props.index}
        href="#"
        className={
          styles.timebutton +
          " " +
          styles.timebuttonUnavailable +
          " unavailable w-button"
        }
      >
        {props.hour}
      </button>
    );
  };

  const DefaultTime = (props) => {
    return (
      <button
        key={props.index}
        href="#"
        className={styles.timebutton + "  w-button"}
        onClick={() => selectThisTime(props.index)}
      >
        {props.hour}
      </button>
    );
  };

  const SelectedTime = (props) => {
    return (
      <div
        key={props.index}
        id="w-node-c3338700-209d-d14d-a70d-e194ee0a24bb-0ceea2d8"
        className={grandParentStyles.wLayoutGrid + " " + styles.timeconfirmgrid}
      >
        <button
          href="#"
          className={
            styles.timebutton +
            " " +
            styles.timebuttonChosen +
            " chosen w-button"
          }
        >
          {props.hour}
        </button>
        <button
          onClick={() => openForm(props.hour)}
          href="#"
          className={
            styles.timebutton +
            " " +
            styles.timebuttonConfirm +
            " confirm w-button"
          }
        >
          Confirm
        </button>
      </div>
    );
  };
  const openForm = (hour) => {
    props.setSelectedHour(hour);
    
    //add time to selectedDate
    const date = dateFns.format(props.selectedDate, "yyyy-MM-dd");
    const dateWithHour = dateFns.parse(date+" "+hour, "yyyy-MM-dd h:mm aaa", props.selectedDate);
    props.setSelectedDate(dateWithHour);

    props.setShowContent('form');
  };

  const selectThisTime = (clickedIndex) => {
    const listHoursCopy = props.listHours.slice();
    const listHoursChanged = listHoursCopy.map((item, index) => {
      if (index === clickedIndex) {
        item.selected = true;
      } else {
        item.selected = false;
      }
      return item;
    });
    props.setListHours(listHoursChanged);
  };

  if (!props.show) {
    return null;
  }
  return (
    <div
      id="w-node-c3338700-209d-d14d-a70d-e194ee0a2459-0ceea2d8"
      className={styles.calendarpickwrap}
    >
      <div className={parentStyles.useractiontitleblock}>
        <div className={parentStyles.smuseractiontitle2}>
          Select a date and time.
        </div>
      </div>
      <div
        className={grandParentStyles.wLayoutGrid + " " + styles.calendargrid}
      >
        <div className={styles.datePickerBlock}>
          <RenderHeader />
          <div
            className={
              grandParentStyles.wLayoutGrid + " " + styles.datepickerGrid
            }
          >
            <RenderDays />
            <RenderCells />
          </div>
        </div>

        <div
          id="w-node-c3338700-209d-d14d-a70d-e194ee0a24b0-0ceea2d8"
          className={styles.timegrid}
        >
          <div className={styles.timepickermonthblock}>
            <RenderHoursHeader />
          </div>
          <div className={styles.timeblockinner}>
            <HoursList />
          </div>
        </div>
      </div>
    </div>
  );
}
