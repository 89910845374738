import styles from "./Shareconfirm.module.css";
import parentStyles from "../home/Home.module.css";
import close_20 from "./../../images/close-20.svg";
import green_checked from "./../../images/green_checked.svg";

export function Shareconfirm(props) {
  if (!props.show) {
    return null;
  }
  return (
    <div className={parentStyles.sharemodal} style={{ display: "flex" }}>
      <div
        className={parentStyles.contactformmodalwrapper + " " + styles.wForm}
      >
        <div className={styles.wFormDone + " " + styles.success}>
          <img
            src={green_checked}
            loading="lazy"
            alt=""
            className={styles.checkicon}
          />
          <div>This window sticker has been shared! </div>
        </div>

        <div
          className={
            parentStyles.closeModalBlock +
            " absolute " +
            parentStyles.closeModalBlockAbsolute
          }
          onClick={() => props.setShowShareconfirm(false)}
        >
          <img
            src={close_20}
            loading="lazy"
            alt=""
            className="close-modal-icon"
          />
        </div>
      </div>
    </div>
  );
}
