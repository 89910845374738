import { configureStore } from '@reduxjs/toolkit';
import homeReducer from '../features/home/homeSlice';
import linkPageReducer from '../features/link_page/linkPageSlice';

export const store = configureStore({
  reducer: {
    home: homeReducer,
    linkPage: linkPageReducer,
  },
});
