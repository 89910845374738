import { useState } from "react";
import styles from "./LeadForm.module.css";
import { useDispatch, useSelector } from "react-redux";
import { sendLeadForm } from "./leadFormSlice";
import {
  vin,
  subdomain,
} from "./../home/homeSlice";

const DynamicButton = ({ requestInProgress }) => {
  if (requestInProgress) {
    return (
      <input
        type="submit"
        disabled
        className={`${styles.largebutton} ${styles.mt10} ${styles['w-button']} ${styles['no-hover']}`}
        value="Please wait ..."
      />
    );
  }

  return (
    <input
      type="submit"
      className={`${styles.largebutton} ${styles.mt10} ${styles['w-button']}`}
      value="Show Window Sticker"
    />
  );
};

const SuccessMessage = ({ showSuccessMessage }) => {
  if (!showSuccessMessage) {
    return;
  }
  return (
    <>
      <div className={styles['w-form-done']} tabIndex="-1" role="region" aria-label="Email Form 16 success">
        <div>Thank you! Your submission has been received!</div>
      </div>
    </>
  )
};

const FormSection = ({
  firstNameErrorClass,
  lastNameErrorClass,
  emailErrorClass,
  submitLeadForm,
  requestInProgress,
  changeFirstName,
  changeLastName,
  changeEmail,
  changePhone,
  showSuccessMessage
}) => {
  if (showSuccessMessage) {
    return;
  }

  return (
    <>
      <form
        id="email-form-16"
        name="email-form-16"
        data-name="Email Form 16"
        method="get"
        className={styles['leadform-2']}
        data-wf-page-id="6639164a2a35d6f188c3ff2e"
        data-wf-element-id="53fcc593-149a-32bc-7c7a-282592a13d4d"
        aria-label="Email Form 16"
        onSubmit={submitLeadForm}
      >
        <div className={styles['inputfieldblock']}>
          <label htmlFor="name-4" className={styles['textfieldlabel-2']}>
            First Name*
          </label>
          <input
            className={`${styles.inputfield} ${styles['w-input']} ${styles[firstNameErrorClass]}`}
            maxLength="256"
            name="name-3"
            data-name="Name 3"
            placeholder=""
            type="text"
            id="name-3"
            onChange={changeFirstName}
          />
        </div>
        <div className={styles['inputfieldblock']}>
          <label htmlFor="name-4" className={styles['textfieldlabel-2']}>
            Last Name*
          </label>
          <input
            className={`${styles.inputfield} ${styles['w-input']} ${styles[lastNameErrorClass]}`}
            maxLength="256"
            name="name-3"
            data-name="Name 3"
            placeholder=""
            type="text"
            id="name-3"
            onChange={changeLastName}
          />
        </div>
        <div className={styles['inputfieldblock']}>
          <label htmlFor="name-4" className={styles['textfieldlabel-2']}>
            Email*
          </label>
          <input
            className={`${styles.inputfield} ${styles['w-input']} ${styles[emailErrorClass]}`}
            maxLength="256"
            name="name-3"
            data-name="Name 3"
            placeholder=""
            type="email"
            id="name-3"
            onChange={changeEmail}
          />
        </div>
        <div className={styles['inputfieldblock']}>
          <label htmlFor="name-4" className={styles['textfieldlabel-2']}>
            Phone
          </label>
          <input
            className={`${styles.inputfield} ${styles['w-input']}`}
            maxLength="15"
            name="name-3"
            data-name="Name 3"
            placeholder=""
            type="tel"
            id="name-3"
            onChange={changePhone}
          />
        </div>
        <DynamicButton requestInProgress={requestInProgress} />
      </form>
    </>
  )
}


export function LeadForm(props) {
  const dispatch = useDispatch();
  const subdomainLocal = useSelector(subdomain);
  const vinLocal = useSelector(vin);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [firstNameErrorClass, setFirstNameErrorClass] = useState("");
  const [lastNameErrorClass, setLastNameErrorClass] = useState("");
  const [emailErrorClass, setEmailErrorClass] = useState("");

  const [requestInProgress, setRequestInProgress] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const submitLeadForm = async (e) => {
    e.preventDefault()
    setShowSuccessMessage(false);

    if (!isValid()) {
      return;
    }

    const formData = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      subdomain: subdomainLocal,
      vin: vinLocal,
    }
    console.log("formData: ", formData);

    setRequestInProgress(true);

    const response = await dispatch(sendLeadForm(formData));
    setRequestInProgress(false);
    if (response.payload.type === 'success') {
      setShowSuccessMessage(true);
      setTimeout(() => {
        props.setDisplayLeadForm(false);
      }, 2000);
      return;
    }
  }

  const isValid = () => {
    let valid = true;

    if (firstName.length === 0 && lastName.length === 0) {
      setFirstNameErrorClass("error");
      setLastNameErrorClass("error");
      valid = false;
    }

    if (firstName.length === 0) {
      setFirstNameErrorClass("error");
      valid = false;
    }

    if (lastName.length === 0) {
      setLastNameErrorClass("error");
      valid = false;
    }

    if (email.length === 0) {
      setEmailErrorClass("error");
      valid = false;
    }

    if (
      email.length > 0 &&
      !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email)
    ) {
      setEmailErrorClass("error");
      valid = false;
    }

    return valid;
  };

  const changeFirstName = (e) => {
    if (e.target.value.lenght > 0) {
      removeRedBorders();
    }
    setFirstName(e.target.value);
  }
  const changeLastName = (e) => {
    if (e.target.value.lenght > 0) {
      removeRedBorders();
    }
    setLastName(e.target.value);
  }

  const changeEmail = (e) => {
    if (e.target.value.length > 0) {
      removeRedBorders();
    }
    setEmail(e.target.value);
  };

  const changePhone = (e) => {
    if (e.target.value.lenght > 0) {
      removeRedBorders();
    }
    setPhone(e.target.value);
  }

  const removeRedBorders = () => {
    setFirstNameErrorClass("");
    setLastNameErrorClass("");
    setEmailErrorClass("");
  };

  if (!props.displayLeadForm) {
    return;
  }

  return (
    <div className={styles['lead-form-modal']}>
      <div className={styles.welcomemessahemodalinner}>

        <div className={styles.wmmodaltitle}>
          <img
            src={props?.images?.default}
            loading="lazy"
            sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 96vw, 90vw"
            srcSet={
              props?.images?.pic_500 +
              " 500w, " +
              props?.images?.pic_800 +
              " 800w, " +
              props?.images?.pic_1080 +
              " 1080w, " +
              props?.images?.pic_1600 +
              " 1600w, " +
              props?.images?.pic_2000 +
              " 2000w, " +
              props?.images?.default +
              " 2389w"
            }
            alt=""
            className={styles.oemlogo} />
          <div className={styles['text-block-2']}>Complete this short form to access this vehicle's interactive window sticker!</div>
        </div>


        <div className="w-form">
          <FormSection
            showSuccessMessage={showSuccessMessage}
            firstNameErrorClass={firstNameErrorClass}
            lastNameErrorClass={lastNameErrorClass}
            emailErrorClass={emailErrorClass}
            requestInProgress={requestInProgress}

            submitLeadForm={submitLeadForm}
            changeFirstName={changeFirstName}
            changeLastName={changeLastName}
            changeEmail={changeEmail}
            changePhone={changePhone}

          />

          <SuccessMessage showSuccessMessage={showSuccessMessage} />
        </div>
      </div>
    </div>
  );
}
