import styles from "./Sharemodal.module.css";
import parentStyles from "../home/Home.module.css";
import close_20 from "./../../images/close-20.svg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendSharemodalData } from "./sharemodalSlice";
import {
  vin,
  subdomain,
} from "./../home/homeSlice";

export function Sharemodal(props) {
  // const [text, setText] = useState("");
  const [email, setEmail] = useState("");
  const [apiError, setApiError] = useState("");
  const [requestInProgress, setRequestInProgress] = useState(false);
  // const [textErrorClass, setTextErrorClass] = useState("");
  const [emailErrorClass, setEmailErrorClass] = useState("");

  const dispatch = useDispatch();
  const subdomainLocal = useSelector(subdomain);
  const vinLocal = useSelector(vin);

  const submitForm = async (e) => {
    e.preventDefault();

    if (!isValid()) {
      return;
    }

    //send data to API
    const data = {
      // phone: text,
      email: email,
      subdomain: subdomainLocal,
      vin: vinLocal
    };

    setRequestInProgress(true);
    const response = await dispatch(sendSharemodalData(data));
    console.log('response', response);
    setRequestInProgress(false);

    setEmail("");

    if (response.payload.type === 'success') {
      props.setShowSharemodal(false);
      props.setShowShareconfirm(true);

      //adding datalayer event
      if (typeof window['dataLayer'] == 'undefined') {
        window['dataLayer'] = [];
      }
      // push to datalayer event and product type
      window['dataLayer'].push({
        event: 'shareToEmailSuccessful',
        productType: 'window_sticker',
        dealership_id: props.mainDealershipId 
      });
      // log to console
      console.log("Push to datalayer: ", {
        event: 'shareToEmailSuccessful',
        productType: 'window_sticker',
        dealership_id: props.mainDealershipId
      })

      return;
    }

    //show the error from api
    setApiError(response.payload.text);
  };

  const isValid = () => {
    let valid = true;
    // CODE COMMENTED RELATED TO REMOVE BY "TEXT" IN SHARE WINDOW STICKER =====================================================
    // if (text.length === 0 && email.length === 0) {
    //   setTextErrorClass("error");
    //   setEmailErrorClass("error");
    //   valid = false;
    // }
    if (email.length === 0) {
      setEmailErrorClass("error");
      valid = false;
    }

    // SAME
    // if (text.length > 0 && !/^\d+$/.test(text)) {
    //   setTextErrorClass("error");
    //   valid = false;
    // }

    if (
      email.length > 0 &&
      !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email)
    ) {
      setEmailErrorClass("error");
      valid = false;
    }

    return valid;
  };
  // const changeText = (e) => {
  //   if (e.target.value.length > 0) {
  //     removeRedBorders();
  //   }
  //   setText(e.target.value);
  // };

  const changeEmail = (e) => {
    if (e.target.value.length > 0) {
      removeRedBorders();
    }
    setEmail(e.target.value);
  };

  const removeRedBorders = (e) => {
    // setTextErrorClass("");
    setEmailErrorClass("");
  };

  const DynamicButton = () => {
    if (requestInProgress) {
      return (
        <div className={styles.mt10Btnblock + " " + styles.mt10btnBlockFullbtn}>
          <input
            type="button"
            value="Please wait..."
            className={styles.primarybutton}
            readOnly
          />
        </div>
      );
    }

    return (
      <div className={styles.mt10Btnblock + " share-confirm " + styles.mt10btnBlockFullbtn}>
        <input
          type="submit"
          value="Share Now"
          className={styles.primarybutton + " w-button"}
        />
      </div>
    );
  };

  const ShowError = () => {
    if (apiError.length > 0) {
      return (
        <div className={styles.wFormFail}>
          <div>{apiError}</div>
        </div>
      );
    }
    return null;
  };

  if (!props.show) {
    return null;
  }
  return (
    <div className={parentStyles.sharemodal} style={{ display: "flex" }}>
      <div className={parentStyles.contactformmodalwrapper + " w-form"}>
        <form
          id="email-form-9"
          name="email-form-9"
          method="get"
          className="contactform"
          onSubmit={submitForm}
        >
          <div className={styles.formTitleBlock}>
            <div className={parentStyles.ptitle}>Share Window Sticker</div>
          </div>
          {/* SAME */}
          {/* <div className={styles.inputfieldblock}>
            <label htmlFor="name-4" className={styles.textfieldlabel}>
              By Text
            </label>
            <input
              type="text"
              className={
                styles.inputfield + " w-input " + styles[textErrorClass]
              }
              maxLength="15"
              name="text"
              placeholder=""
              id="text"
              onChange={changeText}
            />
          </div> */}
          <div className={styles.inputfieldblock}>
            <label htmlFor="name-4" className={styles.textfieldlabel}>
              By Email
            </label>
            <input
              type="email"
              className={
                styles.inputfield + " w-input " + styles[emailErrorClass]
              }
              maxLength="256"
              name="email"
              placeholder=""
              id="email"
              onChange={changeEmail}
            />
          </div>
          <DynamicButton />
          <ShowError />
        </form>

        <div
          className={
            parentStyles.closeModalBlock +
            " absolute " +
            parentStyles.closeModalBlockAbsolute
          }
          onClick={() => props.setShowSharemodal(false)}
        >
          <img
            src={close_20}
            loading="lazy"
            alt=""
            className="close-modal-icon"
          />
        </div>
      </div>
    </div>
  );
}
