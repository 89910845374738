import styles from "./Footer.module.css";
import quotible from "./../../images/quotible.svg";

export function Footer(props) {
  return (
    <>
      <div className={styles.disclaimerblock}>
        <div className={styles.disclaimertitle}>{props.disclaimerTitle}</div>
        <p className={styles.ctrdisclaimersmallprint}>{props.disclaimerText}</p>
        <a
          href={props.urlQuotible}
          target="_blank"
          className={styles.quotiblelink} rel="noreferrer"
        >
          <div>{props.poweredBy}</div>
          <img
            src={quotible}
            loading="lazy"
            alt=""
            className={styles.quotiblelogo}
          />
        </a>
      </div>
    </>
  );
}
