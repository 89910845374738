import styles from "./Schedulevisitmodal.module.css";
import parentStyles from "../home/Home.module.css";
import { useState, useEffect } from "react";
import { Calendar } from "../calendar/Calendar";
import { ScheduleForm } from "../schedule_form/ScheduleForm";
import { ScheduleConfirm } from "../schedule_confirm/ScheduleConfirm";
import * as dateFns from "date-fns";

export function Schedulevisitmodal(props) {
  const [listHours, setListHours] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    dateFns.startOfDay(new Date(), "EE")
  );
  const [selectedHour, setSelectedHour] = useState("");
  const [showContent, setShowContent] = useState("calendar");

  const generateTimes = (interval, start, end) => {
    var x = interval; //minutes interval
    var times = []; // time array
    var tt = 0; // start time
    var ap = ['am', 'pm']; // AM-PM
    var prevap = "am";

    var time = start,
    startH = parseInt(time.split(':')[0]),
    startM = time.split(':')[1],
    startMins = parseInt(startM.split(' ')[0]),
    timeEND = end,
    endH = parseInt(timeEND.split(':')[0]) + 12,
    endM = timeEND.split(':')[1],
    endMins = parseInt(endM.split(' ')[0]);


    //loop to increment the time and push results in array
    for (var i = 0; tt < 24 * 60; i++) {
        var hh = Math.floor(tt/60); // getting hours of day in 0-24 format
        var mm = (tt%60); // getting minutes of the hour in 0-55 format
        //mm will always be 0 as far as interval has been provided as 60
        tt = tt + x;

        if (
            (hh < startH) ||
            (hh === startH && mm < startMins) ||
            (hh > endH || (hh === endH && mm > endMins))
        ) {
            continue;
        }

        let hoursDISP = ("0" + ((hh % 12 === 0) ? 12 : hh % 12)).slice(-2);
        if (prevap == "am" && hh == 12) {
            hoursDISP = "12";
        }

        prevap = ap[Math.floor(hh/12)];
        // pushing data in array in [00:00 - 12:00 AM/PM format]
        times.push(hoursDISP + ':' + ("0" + mm).slice(-2) + ' ' + ap[Math.floor(hh/12)]); 
    }

    return times;
  }

  useEffect(() => {
    const dateFormat = "EEEE";
    const formattedDate = dateFns
      .format(selectedDate, dateFormat)
      .toLowerCase();
    let listHoursData = [];
    // this if added 06/11 - L
    if(props.listHours) {
      const open = props.listHours[formattedDate].open && props.listHours[formattedDate].open !== 'closed'?(props.listHours[formattedDate].open):null;
      const close = props.listHours[formattedDate].close && props.listHours[formattedDate].close !== 'closed'?(props.listHours[formattedDate].close):null;
      if (!open || !close) {
        setListHours([]);
        return;
      }
      const scheduleTimes = generateTimes(30, open+" am", close+" pm");
      const currentTime = new Date();
      const isToday = dateFns.isToday(selectedDate);
      scheduleTimes.forEach((item) => {
        const itemTime = new Date(currentTime.toDateString() + ' ' + item);
        listHoursData.push({
          hour: item,
          // if isToday === true , disabled will be true, wich means that the time is passed and can not be selected
          disabled: isToday && currentTime > itemTime, 
          selected: false,
        });
      });
      setListHours(listHoursData);
    }
  }, [props.listHours, selectedDate]);

  const DynamicContent = () => {
    if (showContent === "calendar") {
      return (
        <Calendar
          show={props.show}
          listHours={listHours}
          setListHours={setListHours}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          dealershipInfo={props.dealershipInfo}
          setShowContent={setShowContent}
          setSelectedHour={setSelectedHour}
          selectedHour={selectedHour}
        />
      );
    }

    if (showContent === "form") {
      return (
        <ScheduleForm
          setShowContent={setShowContent}
          dealershipInfo={props.dealershipInfo}
          mainDealershipId={props.mainDealershipId}
          selectedHour={selectedHour}
          selectedDate={selectedDate}
        />
      );
    }

    if (showContent === "confirm") {
      return (
        <ScheduleConfirm
          dealershipInfo={props.dealershipInfo}
          selectedDate={selectedDate}
        />
      );
    }

    return null;
  };

  if (!props.show) {
    return null;
  }
  return (
    <div className={styles.schedulevisitmodal} style={{ display: "flex" }}>
      <div className={styles.schedulevisitmodalinner}>
        <div
          className={
            parentStyles.closeModalBlock +
            " absolute " +
            parentStyles.closeModalBlockAbsolute
          }
        >
          <img
            src="https://uploads-ssl.webflow.com/62965a5a5e3432478f065224/62965a5a5e3432694606528c_close%20(20).svg"
            loading="lazy"
            alt=""
            className="close-modal-icon"
            onClick={() => props.setShowSchedulevisitmodal(false)}
          />
        </div>
        <div
          className={parentStyles.wLayoutGrid + " " + styles.schedulevisitgrid}
        >
          <div
            id="w-node-c3338700-209d-d14d-a70d-e194ee0a244e-0ceea2d8"
            className={styles.visitsalespanel}
          >
            <div className={styles.visitmodaldelaerlogoblock}>
              <img
                src={props.dealershipInfo.logoOriginal}
                loading="lazy"
                alt=""
                className={styles.dealerlogoMb0 + " " + parentStyles.dealerlogo}
              />
            </div>
            <div className={styles.visitsalesintro}>
              <div className={styles.scheduleVisitTitle}>Schedule Visit</div>
              {props.dealershipInfo.dealership?
                <img
                  src={props.dealershipInfo.dealership}
                  loading="lazy"
                  sizes="100vw"
                  srcSet={
                    props.dealershipInfo.dealership +
                    " 500w, " +
                    props.dealershipInfo.dealership +
                    " 680w "
                  }
                  alt=""
                  className={styles.schedvisitdealerimage}
                />:null}
              <div
                className={
                  parentStyles.dealeraddressblock +
                  " " +
                  styles.dealeraddressblockCtr
                }
              >
                <div className={parentStyles.ptitle}>
                  {props.dealershipInfo.title}
                </div>
              </div>
            </div>
          </div>
          <DynamicContent />
        </div>
      </div>
    </div>
  );
}
