import styles from "./ScheduleForm.module.css";
import parentStyles from "../schedule_visit_modal/Schedulevisitmodal.module.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendScheduleFormData } from "./scheduleFormSlice";
import {
  subdomain,
  vin
} from "../home/homeSlice";
import * as dateFns from "date-fns";

export function ScheduleForm(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [firstNameErrorClass, setFirstNameErrorClass] = useState("");
  const [lastNameErrorClass, setLastNameErrorClass] = useState("");
  const [phoneErrorClass, setPhoneErrorClass] = useState("");
  const [emailErrorClass, setEmailErrorClass] = useState("");
  const [apiError, setApiError] = useState("");
  const [requestInProgress, setRequestInProgress] = useState(false);
  const subdomainLocal = useSelector(subdomain);
  const vinLocal = useSelector(vin);

  const dispatch = useDispatch();

  const submitForm = async (e) => {
    e.preventDefault();

    console.log("submit the form");

    if (!isValid()) {
      return;
    }

    //send data to API
    const data = {
      dealership: subdomainLocal,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      date: dateFns.format(new Date(props.selectedDate), 'yyyy-MM-dd'),
      time: props.selectedHour,
      vin: vinLocal
    };

    setRequestInProgress(true);
    const response = await dispatch(sendScheduleFormData(data));
    setRequestInProgress(false);

    if (response.payload.type === 'success') {
      props.setShowContent("confirm");

      //adding datalayer event
      if (typeof window['dataLayer'] == 'undefined') {
        window['dataLayer'] = [];
      }
      // push to datalayer event and product type
      window['dataLayer'].push({
        event: 'formSubmissionSuccessful',
        productType: 'window_sticker',
        dealership_id: props.mainDealershipId 
      });
      // log to console
      console.log("Push to datalayer: ", {
        event: 'formSubmissionSuccessful',
        productType: 'window_sticker',
        dealership_id: props.mainDealershipId 
      })

      return;
    }

    //show the error from api
    setApiError(response.payload.text);
  };

  const isValid = () => {
    let valid = true;
    if (firstName.length === 0) {
      setFirstNameErrorClass("error");
      valid = false;
    }
    if (lastName.length === 0) {
      setLastNameErrorClass("error");
      valid = false;
    }
    if (phone.length === 0) {
      setPhoneErrorClass("error");
      valid = false;
    } else if (!/^\d+$/.test(phone)) {
      setPhoneErrorClass("error");
      valid = false;
    }

    if (email.length === 0) {
      setEmailErrorClass("error");
      valid = false;
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setEmailErrorClass("error");
      valid = false;
    }
    return valid;
  };

  const changeFirstName = (e) => {
    if (e.target.value.length > 0) {
      setFirstNameErrorClass("");
    }
    setFirstName(e.target.value);
  };

  const changeLastName = (e) => {
    if (e.target.value.length > 0) {
      setLastNameErrorClass("");
    }
    setLastName(e.target.value);
  };

  const changeEmail = (e) => {
    if (e.target.value.length > 0) {
      setEmailErrorClass("");
    }
    setEmail(e.target.value);
  };

  const changePhone = (e) => {
    if (e.target.value.length > 0) {
      setPhoneErrorClass("");
    }
    setPhone(e.target.value);
  };

  const DynamicButton = () => {
    if (requestInProgress) {
      return (
        <input
          type="button"
          value="Please wait..."
          className={styles.primarybutton + " " + styles.wButtonWait}
          readOnly
        />
      );
    }

    return (
      <input
        type="submit"
        value="Schedule Visit"
        className={styles.primarybutton + " schedule-visit-confirm " + styles.wButton}
      />
    );
  };
  const ShowError = () => {
    if (apiError.length > 0) {
      return (
        <div className={styles.wFormFail}>
          <div>{apiError}</div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={parentStyles.leadform + " " + parentStyles.wForm}>
      <form
        id="wf-form-Lead-Capture-Form"
        name="wf-form-Lead-Capture-Form"
        method="get"
        className={styles.leadforminner}
        onSubmit={submitForm}
      >
        <div className={styles.useractiontitleblock}>
          <div className={styles.smuseractiontitle2}>Your Details</div>
        </div>
        <label htmlFor="first-name" className={styles.fieldlabel}>
          First Name
        </label>
        <input
          type="text"
          className={
            styles.textfield + " w-input " + styles[firstNameErrorClass]
          }
          maxLength="256"
          name="firstName"
          placeholder="Enter your first name"
          id="first-name"
          value={firstName}
          onChange={changeFirstName}
          required=""
        />
        <label htmlFor="last-name" className={styles.fieldlabel}>
          Last Name
        </label>
        <input
          type="text"
          className={
            styles.textfield + " w-input " + styles[lastNameErrorClass]
          }
          maxLength="256"
          name="lastName"
          placeholder="Enter your last name"
          id="last-name"
          value={lastName}
          onChange={changeLastName}
          required=""
        />
        <label htmlFor="email" className={styles.fieldlabel}>
          Email
        </label>
        <input
          type="email"
          className={styles.textfield + " w-input " + styles[emailErrorClass]}
          maxLength="256"
          name="email"
          placeholder="Enter your email address"
          id="email"
          onChange={changeEmail}
          required=""
        />
        <label htmlFor="phone" className={styles.fieldlabel}>
          Phone
        </label>
        <input
          type="tel"
          className={styles.textfield + " w-input " + styles[phoneErrorClass]}
          maxLength="15"
          name="phone"
          placeholder="Enter your phone number"
          id="phone"
          value={phone}
          onChange={changePhone}
          required=""
        />

        <div className={styles.formbuttonblock}>
          <span
            className={styles.textLink + " schedule-visit-previous"}
            onClick={() => props.setShowContent("calendar")}
          >
            Previous
          </span>
          <DynamicButton />
        </div>
        <ShowError />
      </form>
    </div>
  );
}
