import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getBgPdfPath } from './actionsAPI';

const initialState = {};

export const getBgPdf = createAsyncThunk(
  'scheduleForm/sendData',
  async (payload) => {
    const response = await getBgPdfPath(payload);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const actionsSlice = createSlice({
  name: "actions",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  
  reducers: {},
});

export default actionsSlice.reducer;


