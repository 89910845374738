import styles from "./Header.module.css";
import parentStyles from "../home/Home.module.css";
import icon_mileage from "./../../images/icon-mileage.svg";
import icon_mpg from "./../../images/icon-mpg.svg";
import { PatternFormat, NumericFormat } from "react-number-format";
import MPGe from "../../images/MPGe.svg";

function MpegComponent(props) {
  if (!props.isElectric) {
    if (props.cityMpg > 0 && props.highwayMpg > 0) {
      return (
        <div className={styles.mpgwrap}>
          <div
            id="w-node-ec1be6aa-2381-609b-dfc0-a765ab060564-0ceea2d8"
            className={styles.mpggrid}
          >
            <div className={styles.mpgblock}>
              <div className={styles.mpglabel}>City MPG</div>
              <div className={styles.mpgvalue}>{props.cityMpg}</div>
            </div>
            <img src={icon_mpg} loading="lazy" alt="" className={styles.iconmpg} />
            <div className={styles.mpgblock}>
              <div className={styles.mpglabel}>Hwy MPG</div>
              <div className={styles.mpgvalue}>{props.highwayMpg}</div>
            </div>
          </div>
          <div className={styles.mpgsmallprint}>{props.mpgDescription}</div>
        </div>
      );
    }
    return null;
  }

  return (
    <div className={styles.mpgewrap}>
      <div className={styles.epaheader}>
        <div className={styles.epatext}>EPA/DOT</div>
        <div>Electric Vehicle Fuel Economy</div>
      </div>
      <div className={styles.mpgewrapinner}>
        <div className={styles.mpggrid}>
          <div className={styles.mpgeblock + " " + styles.icon}>
            <img src={MPGe} loading="lazy" alt="" className={styles.iconmpg} />
          </div>
          <div className={styles.mpgeblock}>
            <div className={styles.mpgelabel}>Combined</div>
            <div className={styles.mpgvalue}>{props.combinedMpg}</div>
          </div>
          <div className={styles.mpgeblock}>
            <div className={styles.mpgelabel}>City</div>
            <div className={styles.mpgvalue}>{props.cityMpg}</div>
          </div>
          <div className={styles.mpgeblock + " " + styles.last}>
            <div className={styles.mpgelabel}>Hwy</div>
            <div className={styles.mpgvalue}>{props.highwayMpg}</div>
          </div>
        </div>
        <div className={styles.mpgsmallprint}>
          {props.mpgDescription}
        </div>
      </div>
    </div>
  );
}

export function Header(props) {

  const slicedUrl = props.dealerUrl.dealerTitle.replace('https://www.', '');

  return (
    <div>
      <div className={parentStyles.wLayoutGrid + " " + styles.vehiclewrap}>
        <div
          id="w-node-_11d8c416-8446-73bd-a727-8f0f0bcf4ff8-0ceea2d8"
          className={styles.brandblock}
        >
          <img
            src={props.images.default}
            loading="lazy"
            sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 96vw, 90vw"
            srcSet={
              props.images.pic_500 +
              " 500w, " +
              props.images.pic_800 +
              " 800w, " +
              props.images.pic_1080 +
              " 1080w, " +
              props.images.pic_1600 +
              " 1600w, " +
              props.images.pic_2000 +
              " 2000w, " +
              props.images.default +
              " 2389w"
            }
            alt=""
            className={styles.oemlogo}
          />
          <span className={styles.urlblock}>
            <a
              href={props.dealerUrl.urlblock}
              className={styles.dealerurl}
              target="_blank"
              rel="noreferrer"
            >
              {slicedUrl}
            </a>
            <a
              href={"tel:" + props.nrTelContact}
              className={parentStyles.dealerurl}
              target="_blank"
              rel="noreferrer"
            >
              <PatternFormat
                value={props.nrTelContact}
                displayType={"text"}
                format="###.###.#####"
              />
            </a>
          </span>
        </div>
        <div
          id="w-node-_1ebd1666-ea80-cf14-5fd1-e8cbe57ec006-0ceea2d8"
          className={styles.vehicledetailswrap}
        >
          <div className={styles.vehiclenameblock} style={{backgroundColor: props.accentColor}}>
            <h1 className={styles.vehicletitle}>
              {props.year} {props.make} {props.model} {props.trim}
            </h1>
            {
              props.mileage > 0?
              <div className={styles.mileageblock}>
                <img
                  src={icon_mileage}
                  loading="lazy"
                  alt=""
                  className={styles.iconMileage}
                />
                <div className={styles.mileagevalue}>
                  Mileage: <NumericFormat value={props.mileage} allowLeadingZeros thousandSeparator="," displayType="text"/>
                </div>
              </div>
              :null
            }
          </div>
          <div className={parentStyles.wLayoutGrid + " " + styles.quickspecs} style={{backgroundColor: props.accentColor}}>
            <div
              id="w-node-_2b719c79-2980-bd32-3214-de9032a1aabf-0ceea2d8"
              className="quickspecsblock"
            >
              <div className={styles.spectitle}>Year: {props.year}</div>
              <div className={styles.spectitle}>Make: {props.make}</div>
              <div className={styles.spectitle}>Model: {props.model}</div>
              <div className={styles.spectitle}>VIN: {props.vin}</div>
            </div>
            <div
              id="w-node-bc5a4654-3df3-285b-51ce-06290576bc9f-0ceea2d8"
              className="quickspecsblock"
            >
              <div className={styles.spectitle}>Engine: {props.engine}</div>
              <div className={styles.spectitle}>
                Transmission: {props.transmission}
              </div>
              <div className={styles.spectitle}>
                Interior Color: {props.interiorColor}
              </div>
              <div className={styles.spectitle}>
                Exterior: {props.exteriorColor}
              </div>
            </div>
            <MpegComponent 
              isElectric={props.isElectric}
              cityMpg={props.cityMpg}
              highwayMpg={props.highwayMpg}
              mpgDescription={props.mpgDescription}
              combinedMpg={props.combinedMpg}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
