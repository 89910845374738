import axios from 'axios';

export async function getBgPdfPath(payload) {
  const route = process.env.REACT_APP_URL2 + '/api/window-sticker/buyers-guide';

  const response = await axios.post(route, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.data;
}
