import React from 'react';
import styles from "./Equipmentmodal.module.css";
// import parentStyles from "../home/Home.module.css";
// import close_20 from "./../../images/close-20.svg";
// import { useState } from "react";
// import { useDispatch } from "react-redux";
// import { sendSharemodalData } from "./sharemodalSlice";
// import { isEmail } from "validator";
import parentStyles from "../home/Home.module.css";

const List = (props) => {
  if (!props.selectedCategoryList || !props.selectedCategoryList.length) {
    return null;
  }
  return (
    <div className={styles.modalsebullets + ' w-richtext'}>
      <h3>{props.selectedCategoryTitle}</h3>
      <ul>
        {props.selectedCategoryList.map((category, index) => (
          <li key={index}>{category}</li>
        ))}
      </ul>
    </div>
  );
}


export function Equipmentmodal(props) {
  // const [text, setText] = useState("");
  // const [email, setEmail] = useState("");
  // const [apiError, setApiError] = useState("");
  // const [requestInProgress, setRequestInProgress] = useState(false);
  // const [textErrorClass, setTextErrorClass] = useState("");
  // const [emailErrorClass, setEmailErrorClass] = useState("");
  //style="display: flex;"

  if (!props.show) {
    return null;
  }

  return (
    <div className={styles.allequipmentmodal} >
      <div className={styles.allequipmodalinner}>
        <div
          data-w-id="ffc4df05-58f1-2ded-d96b-64809bc705e2"
          className={parentStyles.closeModalBlock + " " +  parentStyles.closeModalBlockAbsolute}
          onClick={() => props.setShowEquipmentmodal(false)}
        >
          <img
            src="https://assets.website-files.com/6410a2690f67d03328eea2d7/641222a8de693a1271508f39_close%20(20).svg"
            loading="lazy"
            alt=""
            className="close-modal-icon"
          />
        </div>
        <List 
          selectedCategoryList = {props.selectedCategoryList}
          selectedCategoryTitle = {props.selectedCategoryTitle}
        />
      </div>
    </div>
  );
}
