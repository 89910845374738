import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchData, verifyUserType, getPrintPdfPath } from './homeAPI';

const initialState = {
  subdomain: "",
  vin: "",
  data: {},
  showLoader: true,
  error: null,
  userType: 'admin',
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchJsonData(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchJsonData = createAsyncThunk(
  'home/fetchData',
  async (payload) => {
    const response = await fetchData(payload);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const verifyUserTypeData = createAsyncThunk(
  'home/verifyUserType',
  async (payload) => {
    const response = await verifyUserType(payload);
    return response;
  }
);

export const getPrintPdf = createAsyncThunk(
  'print/sendData',
  async (payload) => {
    const response = await getPrintPdfPath(payload);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const homeSlice = createSlice({
  name: "home",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSubdomain: (state, action) => {
      state.subdomain = action.payload;
    },
    setVin: (state, action) => {
      state.vin = action.payload;
    }, 
    setData: (state, action) => {
      state.data = action.payload;
    },
    setShowLoader: (state, action) => {
      state.showLoader = action.payload;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    }, 
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchJsonData.pending, (state) => {
        //state.status = 'loading';
      })
      .addCase(fetchJsonData.fulfilled, (state, action) => {
        // state.status = 'idle';
        if (action.payload.type === 'error') {
          state.error = action.payload.text;
        }
        state.data = action.payload;
      })
      .addCase(fetchJsonData.rejected, (state, action) => {
        //state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(verifyUserTypeData.fulfilled, (state, action) => {
        // Here we can give proper data to userType state based on response
        state.userType = action.payload?'admin':'customer';
      })
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const { setSubdomain, setVin, setData, setShowLoader, setUserType } = homeSlice.actions;

export const vin = (state) => state.home.vin;
export const subdomain = (state) => state.home.subdomain;
export const data = (state) => state.home.data;
export const showLoader = (state) => state.home.showLoader;
export const error = (state) => state.home.error;
export const userType = (state) => state.home.userType;
// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default homeSlice.reducer;


