export const toggleAnimation = (wasOpened, controlsToggle, controlsCircle) => {
    if (wasOpened) {
        //close it
        controlsToggle.set({backgroundColor: "rgb(230, 230, 230)"});
        controlsCircle.start({x:-27}, {duration: 0.3});
        return;
    }
    //open it
    controlsToggle.set({backgroundColor: "#333"});
    controlsCircle.start({x:0}, {duration: 0.3});
}

export const listAnimation = async(wasClosed, controls) => {
    if (wasClosed) {
        //open it
        await controls.set({height: "40px"});
        await controls.start({height: "auto"});
        await controls.stop({height: "auto"});
        return;
    }
    
    //close it
    await controls.set({height: "auto"});
    await controls.start({height: "40px"});
    await controls.stop({height: "40px"});
}