import styles from "./Warranty.module.css";
import close_20 from "./../../images/close-20.svg";
import parentStyles from "../home/Home.module.css";

function WarrantyItemsList(props) {
  if(props.items || props.items.length) {
    return (
      <ul>
        {props.items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  }
  return null;
}


export function Warranty(props) {
  if (!props.show) {
    return null;
  }
  return (
    <div className={styles.originalwarrantymodal}>
      <div className={styles.originalwarrantymodalinner}>
        <div
          data-w-id="4c1bb755-fb92-5c2e-4fc4-1f52edc09338"
          className={parentStyles.closeModalBlock + " " + parentStyles.closeModalBlockAbsolute}
        >
          <img
            src={close_20}
            loading="lazy"
            alt=""
            className="close-modal-icon"
            onClick={() => props.setShowWarranty(false)}
          />
        </div>
        <div className={styles.modalsebullets + " w-richtext"}>
          <h3>Original Warranty</h3>
          <WarrantyItemsList items={props.warrantyArray} />
        </div>
      </div>
    </div>
  );
}
