import { useParams } from "react-router-dom";
import styles from "./LinkPage.module.css";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { vehicleDetailPageUrl, wsLink, dealershipWebsiteUrl, dealershipNewInventoryUrl, dealershipPreOwnedInventoryUrl, fetchLinksThunk, accentColor, dealershipLogo, accessWebsiteFromWs } from "./linkPageSlice";
import { Helmet } from "react-helmet";
import { useState } from "react";

const DealershipLogo = (props) => {
  if (!props.dealershipLogoLocal) {
    return null;
  };
  return (
    <div className={styles['logo-block']}>
      <img src={props.dealershipLogoLocal} loading="lazy" alt="Dealership" className={styles['logo-dealership']} />
    </div>
  )
};

const PageLinkButtons = (props) => {
  if (!props.href || props.href.length === 0) {
    return null;
  };
  return (
    <a href={props.href} target="_blank" rel="noreferrer" style={props.accentColor ? { backgroundColor: props.accentColor } : null}
      className={styles['link-block'] + " " + styles['w-inline-block']}>
      <div>{props.label}</div>
    </a>
  )
};

const LinksToShow = (props) => {
  if (!props.accessWebsiteFromWsLocal) {
    return (
      <PageLinkButtons
        href={props.wsLinkLocal}
        label="Interactive Window Sticker"
        accentColor={props.accentColorLocal}
      />
    )
  };
  return (
    <>
      <PageLinkButtons
        href={props.wsLinkLocal}
        label="Interactive Window Sticker"
        accentColor={props.accentColorLocal}
      />
      <PageLinkButtons
        href={props.vehicleDetailPageUrlLocal}
        label="Vehicle Detail Page"
        accentColor={props.accentColorLocal}
      />
      <PageLinkButtons
        href={props.dealershipWebsiteUrlLocal}
        label="Dealership Website"
        accentColor={props.accentColorLocal}
      />
      <PageLinkButtons
        href={props.dealershipNewInventoryUrlLocal}
        label="New Vehicles"
        accentColor={props.accentColorLocal}
      />
      <PageLinkButtons
        href={props.dealershipPreOwnedInventoryUrlLocal}
        label="Pre-Owned Vehicles"
        accentColor={props.accentColorLocal}
      />
    </>
  )
};

const LoaderOrLinks = (props) => {
  if (props.showLoader) {
    return (
      <div className={styles['spinnerblock']}>
        <div className={styles['loader']}></div>
      </div>
    )
  };
  return (
    <div className={styles['div-block']}>
      <LinksToShow
        accessWebsiteFromWsLocal={props.accessWebsiteFromWsLocal}
        accentColorLocal={props.accentColorLocal}
        wsLinkLocal={props.wsLinkLocal}
        vehicleDetailPageUrlLocal={props.vehicleDetailPageUrlLocal}
        dealershipWebsiteUrlLocal={props.dealershipWebsiteUrlLocal}
        dealershipNewInventoryUrlLocal={props.dealershipNewInventoryUrlLocal}
        dealershipPreOwnedInventoryUrlLocal={props.dealershipPreOwnedInventoryUrlLocal}
      />
    </div>
  )
};

export function LinkPage() {
  const { dealership, vin } = useParams();

  const [showLoader, setShowLoader] = useState(true);

  const dispatch = useDispatch();
  const vehicleDetailPageUrlLocal = useSelector(vehicleDetailPageUrl);
  const wsLinkLocal = useSelector(wsLink);
  const dealershipWebsiteUrlLocal = useSelector(dealershipWebsiteUrl);
  const dealershipNewInventoryUrlLocal = useSelector(dealershipNewInventoryUrl);
  const dealershipPreOwnedInventoryUrlLocal = useSelector(dealershipPreOwnedInventoryUrl);
  const accentColorLocal = useSelector(accentColor);
  const dealershipLogoLocal = useSelector(dealershipLogo);
  const accessWebsiteFromWsLocal = useSelector(accessWebsiteFromWs);


  useEffect(() => {
    setShowLoader(true);
    dispatch(fetchLinksThunk({ dealership, vin }))
      .then(() => {
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
        console.error("API request failed:", error);
      });
  }, [dispatch, dealership, vin]);

  return (
    <div className={styles['wrapper']}>
      <Helmet>
        <title>QR Code Links</title>
      </Helmet>
      <div className={styles['linkpage-wrapper']}>
        <div className={styles['linkpage-block']}>
          <div className={styles['linkpage-links']}>
            <DealershipLogo
              dealershipLogoLocal={dealershipLogoLocal}
            />
            <div className={styles['w-100']}>
              <LoaderOrLinks
                showLoader={showLoader}
                wsLinkLocal={wsLinkLocal}
                vehicleDetailPageUrlLocal={vehicleDetailPageUrlLocal}
                dealershipWebsiteUrlLocal={dealershipWebsiteUrlLocal}
                dealershipNewInventoryUrlLocal={dealershipNewInventoryUrlLocal}
                dealershipPreOwnedInventoryUrlLocal={dealershipPreOwnedInventoryUrlLocal}
                accentColorLocal={accentColorLocal}
                accessWebsiteFromWsLocal={accessWebsiteFromWsLocal}
              />
            </div>
            <a href="https://www.quotible.com/" target="_blank" rel="noreferrer" className={styles['q_credit-link']}>Powered by Quotible</a>
          </div>
        </div>
      </div>
    </div>
  );
};
