import axios from 'axios';

// A mock function to mimic making an async request for data
export async function fetchData(payload) {
  const res = await axios.get(process.env.REACT_APP_URL2+'/api/window-sticker-data?dealership='+payload.subdomain+'&vin='+payload.vin);
  return res.data.response;
}

export async function verifyUserType(payload) {
  const res = await axios.post(process.env.REACT_APP_URL2+'/api/window-sticker/verify-admin', payload);
  return res.data.isAdmin;
}

export async function getPrintPdfPath(payload) {
  const route = process.env.REACT_APP_URL2+'/api/window-sticker/' + payload.route;

  const response = await axios.post(route, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.data;
}
