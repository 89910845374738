import styles from "./Loader.module.css";
import { motion } from "framer-motion";

const Logo = (props) => {
  if (!props.images) {
    return;
  }
  return (
    <div className={styles.preloaderrepheadblock}>
      <img src={props.images.default} loading="lazy" width="80" height="80" srcSet={props.images.pic_500+" 500w, "+props.images.pic_800+" 800w, "+props.images.pic_1080+" 1080w, "+props.images.pic_1600+" 1600w, "+props.images.pic_2000+" 2000w, "+props.images.default+" 2389w"} sizes="(max-width: 479px) 90vw, 138.78125px" alt="" className={styles.brand} />
    </div>
  )
}
export function Loader(props) {
  return (
    <div>
      <div style={{display:"flex"}} className={styles.windowstickerpreloader}>
        <motion.div 
          initial={{ opacity: 0, y: 20}}
          animate={{ opacity: 1, y: -20 }}
          transition={{ duration: 1 }}
          className={styles.updatedpreloaderinner}
        >
          <Logo images={props.images}/>
          <div className={styles.windowstickerloadertext}>
            Please wait a moment while we generate the Window Sticker for your vehicle.
          </div>
          <div className={styles.preloaderprogressbar}>
            <motion.div 
              className={styles.loaderprogressbarinner} 
              animate={{ x: ["-100%", "100%"] }}
              transition={{ duration: 2, repeat: Infinity }}
            />
          </div>
        </motion.div>
      </div>
    </div>
  );
}
