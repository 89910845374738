// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Shareconfirm_wForm__vAsfL {\n  margin: 0 0 15px;\n}\n.Shareconfirm_success__Phhgj {\n  background-color: rgba(221, 221, 221, 0);\n  padding: 40px 0px 40px 0px;\n}\n.Shareconfirm_wFormDone__KaNz0 {\n  text-align: center;\n  padding: 20px 40px 20px 40px;\n}\n.Shareconfirm_checkicon__wTY9M {\n  width: 20px;\n  margin-bottom: 5px;\n}\nimg {\n  max-width: 100%;\n  vertical-align: middle;\n  display: inline-block;\n}\n\n\n\n", "",{"version":3,"sources":["webpack://./src/features/share_confirm/Shareconfirm.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,wCAAwC;EACxC,0BAA0B;AAC5B;AACA;EACE,kBAAkB;EAClB,4BAA4B;AAC9B;AACA;EACE,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,sBAAsB;EACtB,qBAAqB;AACvB","sourcesContent":[".wForm {\n  margin: 0 0 15px;\n}\n.success {\n  background-color: rgba(221, 221, 221, 0);\n  padding: 40px 0px 40px 0px;\n}\n.wFormDone {\n  text-align: center;\n  padding: 20px 40px 20px 40px;\n}\n.checkicon {\n  width: 20px;\n  margin-bottom: 5px;\n}\nimg {\n  max-width: 100%;\n  vertical-align: middle;\n  display: inline-block;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wForm": "Shareconfirm_wForm__vAsfL",
	"success": "Shareconfirm_success__Phhgj",
	"wFormDone": "Shareconfirm_wFormDone__KaNz0",
	"checkicon": "Shareconfirm_checkicon__wTY9M"
};
export default ___CSS_LOADER_EXPORT___;
