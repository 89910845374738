import styles from "./Actions.module.css";
import parentStyles from "../home/Home.module.css";

import icon_share from "./../../images/icon-share.svg";
import print from "./../../images/print.svg";
import icon_call from "./../../images/icon-call.svg";
import icon_dealerInfo from "./../../images/icon-dealerInfo.svg";
import icon_visit from "./../../images/icon-visit.svg";
import icon_warranty from "./../../images/icon-warranty.svg";
import warranty from "./../../images/warranty.png";
import icon_save from "./../../images/icon-save.svg";
import { useState } from "react";
import { getBgPdf } from "./actionsSlice";
import { useDispatch } from "react-redux";

const PrintButton = (props) => {
  if (props.printLoading) { 
    return (
      <div className={parentStyles['loader-printtypeblock'] + " " + parentStyles.wloader}>
        <div className={parentStyles.spinner + " " + parentStyles['w-embed']}>
          <div className={parentStyles.loader}>
          </div>
        </div>
      </div>
    );
  }

  const standardEquipmentModified = props.categories.map(categoriesObject => {
    if (categoriesObject.items) {
      return { ...categoriesObject, items: categoriesObject.items.slice(0, 3) };
    }
    return categoriesObject;
  });

  return (
    <span
      className={
        styles.fixedaction +
        " w-inline-block action-print " +
        styles.fixedactionMobilehidden
      }
      onClick={() => props.handlePrint('desktop', standardEquipmentModified)}
    >
      <img
        src={print}
        loading="lazy"
        alt=""
        className={styles.fixedactionicon}
      />
      <div>Print</div> 
    
    </span>
  )
}

const SaveButton = (props) => {
  if (props.printLoading) {
    return (
      <div className={parentStyles['loader-printtypeblock'] + " " + parentStyles.wloader}>
        <div className={parentStyles.spinner + " " + parentStyles['w-embed']}>
          <div className={parentStyles.loader}>
          </div>
        </div>
      </div>
    );
  }
  const standardEquipmentModified = props.categories.map(categoriesObject => {
    if (categoriesObject.items) {
      return { ...categoriesObject, items: categoriesObject.items.slice(0, 3) };
    }
    return categoriesObject;
  });
  return (
    <span
      rel="noreferrer"
      className={
        styles.fixedaction +
        " " +
        styles.fixedactionDeskhidden +
        " w-inline-block action-save "
      }
      onClick={() => props.handlePrint('mobile', standardEquipmentModified)}
    >
      <img
        src={icon_save}
        loading="lazy"
        alt=""
        className={styles.fixedactionicon}
      />
      <div>Save</div>
    </span>
  );
}

const WarrantyInfo = (props) =>  {
  if (props.originalWarranty.length > 0) {
    return (
      <span
        className={styles.fixedaction + " w-inline-block action-warranty"}
        onClick={() => props.setShowWarranty(true)}
      >
        <img
          src={warranty}
          loading="lazy"
          alt=""
          className={styles.fixedactionicon}
        />
        <div>Warranty</div>
      </span>
    )
  };
  return null;
}

const BuyersGuideIcon = (props) => {
  if(props.userType === 'customer') {
    return null;
  }
  return (
    <span
      className={`${styles.fixedaction} w-inline-block ${props.buyersGuideProgress ? styles['disabled-content'] : ''} action-buyers-guide`}
      onClick={() => props.handleClick()}
    >
      <BuyersGuideContent
        showLoader={props.getBgReqInProgress}
      />
    </span>
  );
}

const BuyersGuideContent = (props) => {
  if (props.showLoader) {
    return(
        <div className={`${parentStyles['loader-printtypeblock']} ${parentStyles.wloader}`}>
          <div className={`${parentStyles.spinner} ${parentStyles['w-embed']}`}>
            <div className={parentStyles.loader}></div>
          </div>
        </div>
    );
  }

  return (
    <>
      <img src={icon_warranty} loading="lazy" alt="" className={styles.fixedactionicon} />
      <div>Buyers Guide</div>
    </>
  );
};

export function Actions(props) {
  const dispatch = useDispatch();
  const [getBgReqInProgress, setGetBgReqInProgress] = useState(false);
  const handleClick = async () => {
    setGetBgReqInProgress(true);
    let dataToSend = {};
    dataToSend = {
      data: {}
    };
    const result = await dispatch(getBgPdf(dataToSend));
    if (!result?.payload?.path) {
      console.log('something went wrong', result);
      return;
    }
    const pdfWindow = window.open();
    pdfWindow.location.href = result.payload.path;
    setGetBgReqInProgress(false);
  };

  return (
    <div className={styles.fixedactionwrap}>
      <div
        className={
          parentStyles.wLayoutGrid +
          " " +
          styles.fixedactiongrid +
          " " +
          styles.fixedactionwrapinner +
          " " + 
          (props.warrantyArray.length === 0 ? styles.gridWithNoWarranty : '') +
          " " +
          (props.userType === 'customer' ? styles.customerGrid : '')
        }
      >
        <span
          className={styles.fixedaction + " w-inline-block action-share"}
          onClick={() => props.setShowSharemodal(true)}
        >
          <img
            src={icon_share}
            loading="lazy"
            alt=""
            className={styles.fixedactionicon}
          />
          <div>Share</div>
        </span>

        <BuyersGuideIcon 
          userType={props.userType}
          getBgReqInProgress={getBgReqInProgress}
          handleClick={handleClick}
        />
        
        <a
          href={"tel:" + props.nrTelContact}
          rel="noreferrer"
          id="w-node-_992fc058-1bd8-f4fa-8f10-a367b046e002-0ceea2d8"
          className={
            styles.fixedaction +
            " " +
            styles.fixedactionDeskhidden +
            " w-inline-block"
          }
        >
          <img
            src={icon_call}
            loading="lazy"
            alt=""
            className={styles.fixedactionicon}
          />
          <div>Call</div>
        </a>
        
        <span
          className={styles.fixedaction + " w-inline-block action-schedule-visit"}
          onClick={() => props.setShowSchedulevisitmodal(true)}
        >
          <img
            src={icon_visit}
            loading="lazy"
            alt=""
            className={styles.fixedactionicon}
          />
          <div>Visit</div>
        </span>

        <WarrantyInfo
          setShowWarranty={props.setShowWarranty}
          originalWarranty={props.warrantyArray}
        />

        <PrintButton 
          printLoading={props.printLoading['desktop']}
          handlePrint={props.handlePrint}
          categories={props.categories}
        />

        <SaveButton 
          printLoading={props.printLoading['mobile']}
          categories={props.categories}
          handlePrint={props.handlePrint}

        />

        <span
          id="w-node-_9a379e4f-5d0d-5b34-46a4-844fd44a7e9e-0ceea2d8"
          className={styles.fixedaction + " w-inline-block action-dealer-info"}
          onClick={() => props.setShowDealerinfomodal(true)}
        >
          <img
            src={icon_dealerInfo}
            loading="lazy"
            alt=""
            className={styles.fixedactionicon}
          />
          <div>Dealer</div>
        </span>
      </div>
    </div>
  );
}
